import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion'; // Import framer-motion
import { MEDIA_URL } from '../../axios';
import officeimg from '../../Assets/Home/Photos/SaptagonOffice5.png';

const Hero = () => {
  const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

  // Animation variants for elements
  const textVariant = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', stiffness: 50, delay: 0.2 },
    },
  };

  const imageVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', stiffness: 50, delay: 0.4 },
    },
  };

  const buttonVariant = {
    hover: {
      scale: 1.05,
      transition: { yoyo: Infinity, duration: 0.3 },
    },
  };

  return (
    <div className='h-auto md:h-auto md:max-xl:h-[35vh] max-sm:flex-col max-sm:items-center max-sm:py-0 relative overflow-hidden flex items-start justify-start py-10'>
      {/* Image Animation */}
      <motion.img
        src={officeimg}
        alt="About Us"
        className='max-sm:hidden w-[40%] xl:w-[35vw] right-14 xl:right-28 top-20 absolute z-0 rounded-xl'
        initial="hidden"
        animate="visible"
        variants={imageVariant}
      />
      <motion.img
        src={aboutsplashbg}
        alt="About Us"
        className='max-sm:top-4 max-sm:w-[60vw] max-sm:left-2 w-[22vw] sm:max-xl:top-32 sm:max-xl:w-[30vw] top-[17vh] left-14 absolute -z-10'
        initial="hidden"
        animate="visible"
        variants={imageVariant}
      />
      
      {/* Text and Button Animation */}
      <motion.div
        className='herobg2 max-lg:pt-0 max-sm:gap-4 max-lg:px-6 flex flex-col px-20 sm:max-xl:px-14 pt-10 gap-8 z-10'
        initial="hidden"
        animate="visible"
        variants={textVariant}
      >
        <p className='darkblue text-2xl xl:text-5xl font-bold w-[50%] leading-snug'>
          ABOUT <br /><span className='blue'>SAPTAGON</span>
        </p>
        <p className='max-sm:w-full sm:max-xl:w-[50%] text-justify w-[40%] leading-relaxed text-sm md:text-md'>
          Founded over a decade ago, Saptagon Asia Pvt Ltd (formerly G Eng Advisory Pvt Ltd) 
          is a pioneering consultancy specializing in design, supervision, safety, and 
          NABL accredited lab and machinery services. With a strong team of 300+ 
          in house professionals, we lead the transformation of transportation 
          infrastructure. From inception to completion, we ensure excellence in design 
          and construction supervision, prioritizing stringent safety standards. 
          Our commitment to quality and sustainability defines our role as industry leaders 
          in shaping the future of infrastructure development.
        </p>
        <div className='flex gap-8 mt-4'>
          <NavLink to='/Contact-us'>
            <motion.div
              className="btn transition-all text-sm md:text-md rounded-full px-6 py-[12px] text-white"
              whileHover="hover"
              variants={buttonVariant}
            >
              View More
            </motion.div>
          </NavLink>
        </div>
      </motion.div>

      {/* Mobile Image Animation */}
      <div className='md:hidden flex justify-center'>
        <motion.img
          src={officeimg}
          alt="About Us"
          className='mt-10 w-[85%] rounded-lg'
          initial="hidden"
          animate="visible"
          variants={imageVariant}
        />
      </div>
    </div>
  );
};

export default Hero;
