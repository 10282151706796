import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { API } from '../../axios';
import Sectorsbg from '../../Assets/Home/Sectorsbg.png'
// Roads & Bridges
import Roads1 from '../../Assets/Home/Roads1.png';
import Roads2 from '../../Assets/Home/Roads2.png';
import Roads3 from '../../Assets/Home/Roads3.png';
import Roads4 from '../../Assets/Home/Roads4.png';
import Roads5 from '../../Assets/Home/Roads5.png';
import Roads6 from '../../Assets/Home/Roads6.png';
import Roads7 from '../../Assets/Home/Roads7.png';

// Tunnels
import Tunnel1 from '../../Assets/Home/Tunnel1.png';
import Tunnel2 from '../../Assets/Home/Tunnel2.png';
import Tunnel3 from '../../Assets/Home/Tunnel3.png';
import Tunnel4 from '../../Assets/Home/Tunnel4.png';
import Tunnel5 from '../../Assets/Home/Tunnel5.png';
import Tunnel6 from '../../Assets/Home/Tunnel6.png';

// Urban Infrastructure
// import Urban1 from '../../Assets/Home/Urban1.png';
// import Urban2 from '../../Assets/Home/Urban2.png';
import Urban3 from '../../Assets/Home/Urbaninfra3.png';
import Urban4 from '../../Assets/Home/Urbaninfra4.png';
import Urban5 from '../../Assets/Home/Urbaninfra5.png';
import Urban6 from '../../Assets/Home/Urbaninfra6.png';

// Transport Planning
// import Transport1 from '../../Assets/Home/Transport1.png';
// import Transport2 from '../../Assets/Home/Transport2.png';
import Transport3 from '../../Assets/Home/Transport3.png';
import Transport4 from '../../Assets/Home/Transport4.png';
import Transport5 from '../../Assets/Home/Transport5.png';
import Transport6 from '../../Assets/Home/Transport6.png';

// Railway
import Railway1 from '../../Assets/Home/Railway1.png';
import Railway2 from '../../Assets/Home/Railway2.png';
import Railway3 from '../../Assets/Home/Railway3.png';
// import Railway4 from '../../Assets/Home/Railway4.png';
// import Railway5 from '../../Assets/Home/Railway5.png';
// import Railway6 from '../../Assets/Home/Railway6.png';

// // Expressway
// import Expressway1 from '../../Assets/Home/Expressway1.png';
// import Expressway2 from '../../Assets/Home/Expressway2.png';
// import Expressway3 from '../../Assets/Home/Expressway3.png';
// import Expressway4 from '../../Assets/Home/Expressway4.png';
// import Expressway5 from '../../Assets/Home/Expressway5.png';
// import Expressway6 from '../../Assets/Home/Expressway6.png';

// // Metro
// import Metro1 from '../../Assets/Home/Metro1.png';
// import Metro2 from '../../Assets/Home/Metro2.png';
// import Metro3 from '../../Assets/Home/Metro3.png';
// import Metro4 from '../../Assets/Home/Metro4.png';
// import Metro5 from '../../Assets/Home/Metro5.png';
// import Metro6 from '../../Assets/Home/Metro6.png';

// // Hydro Projects (Waterways)
// import Hydro1 from '../../Assets/Home/Hydro1.png';
// import Hydro2 from '../../Assets/Home/Hydro2.png';
// import Hydro3 from '../../Assets/Home/Hydro3.png';
// import Hydro4 from '../../Assets/Home/Hydro4.png';
// import Hydro5 from '../../Assets/Home/Hydro5.png';
// import Hydro6 from '../../Assets/Home/Hydro6.png';

import highwayicon from '../../Assets/Home/highwayicon.svg'
import bridgeicon from '../../Assets/Home/bridgeicon.svg'
import ruralrods from '../../Assets/Home/ruralrods.svg'
import RoadsArrows from '../../Assets/Home/RoadsArrows.png'
import mobarrows from '../../Assets/Home/mobarrow.svg'
import tunnel1 from '../../Assets/Home/tunnel1.svg'
import tunnel2 from '../../Assets/Home/tunnel2.svg'
import tunnel3 from '../../Assets/Home/tunnel3.svg'
import Getintouch from '../../Components/About/Getintouch';

const NewSectorDetails = () => {
    const [SectorsData, setSectorsData] = useState([]);
    const [selectedSector, setSelectedSector] = useState(null);
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    const getSectorsData = async () => {
        try {
        const res = await API.get(`/website/sectorsdetail/`);
        setSectorsData(res.data);
        setLoading(false);
        } catch (err) {
        console.error('Failed to fetch sectors data', err);
        }
    };

    useEffect(() => {
        getSectorsData();
    }, []);

    const currentSector = location.pathname.slice(9).replace(/-/g, ' ');

    useEffect(() => {
        if (SectorsData.length > 0) {
        const sector = SectorsData.find(item => item.name === currentSector);
        if (sector) {
            setSelectedSector(sector);
        } else {
            setLoading(false)
            setSelectedSector({name:"No Data found for this Sector."})
            // setSelectedSector(SectorsData[0]);
        }
        }
    }, [currentSector, SectorsData]);


    // Default fallback images
    const fallbackImages = [Roads1, Roads2, Roads3, Roads5, Roads6, Roads7];

    const sectorImages = {
        "Tunnels": [Tunnel1, Tunnel2, Tunnel3, Tunnel4, Tunnel5, Tunnel6],
        "Roads & Bridges": [Roads1, Roads2, Roads3, Roads5, Roads6, Roads7],
        "Metro": fallbackImages,
        "Urban Infrastructure": [...fallbackImages.slice(0,2), Urban3, Urban4, Urban5, Urban6],
        "Waterways": fallbackImages,
        "Transport Planning": [...fallbackImages.slice(0,2), Transport3, Transport4, Transport5, Transport6],
        "Railway": [Railway1, Railway2, Railway3, ...fallbackImages.slice(3)],
        "Expressway": fallbackImages,
    };
    const selectedImages = sectorImages[selectedSector?.name] || [];
    

    const data = ["2 million kilometres of roads with almost 960,000 kilometers of surfaced roads","Fifty-three highways, almost 20,000 kilometers in length","National highways carry about 40 percent of road traffic","Around 60 percent of all passenger traffic travels by road."]
    const data2 = [
        {
            icon:highwayicon,
            title:"Highways & Expressways",
            description:"We create high-performance highways and expressways that promote efficient movement of people and goods. We employ cutting-edge design principles and advanced materials to ensure safety, durability, and minimal environmental impact."
        },{
            icon:bridgeicon,
            title:"Bridges",
            description:"Our bridge design expertise encompasses a broad spectrum, from elegant pedestrian bridges to complex multi-span structures. We prioritize aesthetics, functionality, and long-term sustainability, considering factors like traffic volume, seismic resilience, and ease of maintenance."
        },{
            icon:ruralrods,
            title:"Rural Roads",
            description:"We recognize the unique challenges faced by rural communities in terms of accessibility and infrastructure development. Our team designs and implements rural road projects that improve connectivity to essential services, markets, and educational."
        },
    ]

    const data3 = [
        {
            img:Roads5,
            title:"NHAI",
            servicetype:"Road Construction",
        },{
            img:Roads6,
            title:"PWD Haryana",
            servicetype:"Super Vision",
        },{
            img:Roads7,
            title:"HSSIDC",
            servicetype:"Authority Engineer",
        },
    ]

    if (loading) {
        return <div className='flex justify-center items-center h-[90vh] text-4xl darkblue font-semibold'>Loading...</div>;
    }
    else if(selectedImages.length===0){
        return <div className='flex justify-center items-center h-[80vh] text-4xl darkblue font-semibold'>No data Available.</div>;
    }

    return (
        <div className='flex flex-col gap-0'>
            {/* 1st Section */}
            <div className='flex flex-col gap-0 pb-16 md:py-16'>
                <div className='flex items-center justify-start w-full min-h-[18dvh] md:min-h-[35dvh] bg-no-repeat bg-cover bg-center' style={{backgroundImage:`url(${Sectorsbg})`}}>
                    <p className='text-4xl md:text-7xl text-white px-4 py-4 md:px-14 font-semibold'>{selectedSector?.name==="Waterways"?"Hydro Projects":selectedSector?.name}</p>
                </div>
                <div className='relative flex flex-col px-4 md:px-14 pt-10'>
                    <p className='text-5xl md:text-8xl tracking-wider uppercase font-semibold md:font-bold text-[#F4F4F4]'>About</p>
                    <p className='text-2xl md:text-5xl purple font-semibold -mt-6 md:-mt-10'>{selectedSector?.name==="Waterways"?"Hydro Projects":selectedSector?.name}</p>
                </div>
                <div className='flex flex-col md:flex-row gap-2 md:py-10 py-6 '>
                    <div className='md:w-[90%] flex items-center justify-between md:justify-start md:gap-8 px-4 md:px-14'>
                        <img src={selectedImages[0]} alt="" className='w-[42%] md:w-[30%] rounded-md'/>
                        <img src={mobarrows} alt="" className='flex md:hidden'/>
                        <div className='hidden md:flex flex-col items-center gap-4 font-semibold'>
                            <div className='w-full flex items-center justify-between'>
                                <p>Route Evaluation</p>
                                <p>Traffic Control</p>
                            </div>
                            <img src={RoadsArrows} alt=""/>
                            <p>Safety Planning</p>
                        </div>
                        <img src={selectedImages[1]} alt="" className='w-[42%] md:w-[30%] rounded-md'/>
                    </div>
                    <div className='md:hidden flex items-center justify-center gap-4 font-semibold text-sm'>
                        <p>Route Evaluation</p>
                        <p>Traffic Control</p>
                        <p>Safety Planning</p>
                    </div>
                </div>
                <div className='md:w-[95%] px-4 md:px-14'>
                    <p dangerouslySetInnerHTML={{ __html: selectedSector?.description?.replace(/CivilMantra/g, 'Saptagon') }} />
                </div>
            </div>
            {/* 2nd Section */}
            <div className='flex flex-col items-start gap-8 px-4 md:px-14 py-10 md:py-16 bg-[#f3f0f9]'>
                <p className='text-2xl md:text-3xl font-semibold text-black'>The staggering statistics tells it all</p>
                <div className='w-full flex flex-col flex-wrap gap-4'>
                    {data.map((item, i)=>(
                        <p className='flex-1 p-4 md:px-6 leading-snug rounded-md md:text-lg font-semibold cursor-pointer bg-[#EDEAF3] hover:bg-[#DCD4EE] hover:text-black text-black md:darkgray duration-300'>{item}</p>
                    ))}
                </div>
            </div>
            {/* 3rd Section */}
            <div className='relative flex flex-col items-start gap-14 px-4 md:px-14 py-0 md:py-16'>
                <div className='flex flex-col gap-0 items-start pb-20'>
                    <div className='relative flex flex-col pt-10'>
                        <p className='text-5xl md:text-8xl tracking-wider uppercase font-semibold md:font-bold text-[#F4F4F4]'>Development</p>
                        <p className='text-2xl md:text-5xl purple font-semibold -mt-6 md:-mt-10'>{selectedSector?.name==="Waterways"?"Hydro Projects":selectedSector?.name}</p>
                    </div>
                    <div className='flex flex-col md:flex-row items-center justify-around gap-4 md:gap-0 pt-10 md:pt-20'>
                        <img src={selectedImages[2]} alt="" className='w-[80%] md:w-[22%]'/>
                        <p className='md:w-[50%] md:text-xl text-justify md:text-left'>
                            In addition, providing access to employment, social, health and education services 
                            makes a road network crucial in fighting against poverty. As per the National Highways 
                            Authority of India, about 65% of freight and 80% passenger traffic is carried by the roads. <br /><br /> 
                            As India continues its forward march as a global economic power, her urban transport network 
                            is fast becoming the most important infra-structure to support its mega growth.
                        </p>
                    </div>

                    <div className='relative flex flex-col pt-10 md:pt-20'>
                        <p className='text-5xl md:text-8xl tracking-wider uppercase font-semibold md:font-bold text-[#F4F4F4]'>Providing</p>
                        <p className='text-2xl md:text-5xl purple font-semibold -mt-6 md:-mt-10'>Best Services</p>
                    </div>
                    <div className='w-full min-h-[40dvh] md:min-h-[65dvh] bg-cover flex flex-col items-center bg-no-repeat bg-center mt-10' style={{backgroundImage:`url(${Roads4})`}}>
                        <div className='flex flex-col items-center gap-4 md:gap-10 md:w-[50%] md:pt-20 md:text-center'>    
                            <p className='text-2xl md:text-3xl font-semibold'>Building Enduring Connections: Roads & Bridges</p>
                            <p className='md:w-[85%] darkgray md:text-lg text-justify md:text-left font-normal'>
                                At Saptagon, we understand the critical role that roads and bridges play in connecting communities, 
                                facilitating trade, and driving economic prosperity. Our team of skilled engineers and experienced 
                                project managers works collaboratively with clients to design, construct, and supervise the development of:
                            </p>
                        </div>
                    </div>

                    <div className='w-full grid gap-10 md:gap-32 md:grid-cols-3 grid-cols-1'>
                        {data2.map((item,i)=>(
                            <div className='group border-[1px] flex flex-col items-center justify-center gap-6 px-6 py-6 md:py-10 text-center rounded-md text-black hover:bg-[#8364C3] duration-300 cursor-pointer'>
                                <img src={item.icon} alt={item.title} className='border-2 p-3 bg-transparent w-28 h-28 rounded-full'/>
                                <p className='group-hover:text-white font-semibold text-2xl md:text-lg'>{item.title}</p>
                                <p className='group-hover:text-white md:darkgray leading-snug'>{item.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* 4th Section */}
            <div className='w-full flex flex-col items-start gap-8 py-10 pb-40 px-4 md:px-14 bg-[#f3f0f9]'>
                <div className='relative flex flex-col'>
                    <p className='text-5xl md:text-8xl tracking-wider uppercase font-semibold md:font-bold text-[#ebebeb]'>Our Latest</p>
                    <p className='text-2xl md:text-5xl purple font-semibold -mt-6 md:-mt-10'>Projects</p>
                </div>
                <div className='flex flex-col md:flex-row items-center gap-20 md:gap-10 pt-6 md:pt-10'>
                    {data3.map((item,i)=>(
                        <div className='relative flex flex-col items-center cursor-pointer'>
                            <img src={selectedImages[i+3]} alt={item.title} className='w-full'/>
                            <div className={`absolute top-[85%] p-4 bg-[#8364C3] text-white rounded-sm flex flex-col items-center gap-1 shadow-lg ${i%2!==0?"top-[72.2%] w-[100%] ":"top-[80%] w-[80%]"}`}>
                                <p className='font-semibold'>{item.title}</p>
                                <p>{item.servicetype}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* Get in touch */}
            <Getintouch/>
        </div>
    )
}

export default NewSectorDetails