import React from 'react'

const SolutionCard = ({img, name, description}) => {
  return (
    <div className='group max-sm:w-[100%] max-sm:flex-[1_0_75%] w-full cursor-pointer flex flex-col flex-[0_1_23%] justify-start items-center gap-4 rounded-3xl bg-white py-6 shadow-[0_5px_20px_0px_rgba(0,0,0,0.05)] hover:ease-in transition-all duration-300 hover:shadow-[0_5px_20px_0px_rgba(0,0,0,0.1)]'>
        <div className="p-6 rounded-2xl bg-[rgba(131,100,195,0.1)] group-hover:bg-[#8364C3] transition-all duration-500">
          <img src={img} alt={name} className='h-8 md:h-12 solutioncardimg'/>
        </div>
        <p className='max-sm:w-[70%] w-[50%] text-center text-md md:text-lg black font-semibold'>{name}</p>
        {/* <p className='w-[70%] text-center darkgray text-sm'>{description}</p> */}
    </div>
  )
}

export default SolutionCard