import React, { useEffect } from 'react'
// import Footerbg from '../../Assets/Footer/Footerbg.png'
import { NavLink, useLocation } from 'react-router-dom'
import { MEDIA_URL } from '../../axios'
import logo from '../../Assets/Home/Logo.png'

const Footer = () => {
  // const logo = MEDIA_URL + 'SeptagonLogo.svg';
  const facebookicon = MEDIA_URL +'Footer/facebook.svg';
  const linkedinicon = MEDIA_URL +'Footer/linkedin.svg';
  const linedivider = MEDIA_URL +'Footer/linedivider.svg';

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);
  
  return (
    <div className='flex footer-cont circularstd flex-col'>
      <div className='max-sm:text-xl w-full max-sm:flex-col max-sm:pt-10 flex justify-between items-start pt-20 md:px-14 px-4 gap-4'>
        <div className='max-sm:w-full sm:max-xl:w-[35%] w-[30%] flex flex-col items-center gap-2'>
          <NavLink to="/">
            <img src={logo} alt="Septagon Logo" className='max-sm:h-auto max-sm:mb-4 sm:max-xl:h-auto h-24'/>
          </NavLink>
        </div>

        <div className='max-sm:gap-12 max-sm:w-full max-sm:flex-col w-[60%] flex justify-between'>
          <div className='max-sm:w-full sm:max-xl:w-[20%] w-[15%] flex flex-col gap-2'>
            <h4 className='text-lg text-white font-semibold'>Quick Links</h4>
            <ul className='max-sm:text-sm max-sm:gap-2 flex flex-col gap-4 lightgray'>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/About-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/Projects">Projects</NavLink>
              </li>
              <li>
                <NavLink to="/Contact-us">Contact Us</NavLink>
              </li>
            </ul>
          </div>

          <div className='max-sm:w-full w-[32%] flex flex-col gap-2'>
            <h4 className='text-lg text-white font-semibold'>Location</h4>
            <p className='max-sm:text-sm lightgray'>
              DCG4 - 1708, Tower 4, DLF Corporate Greens, Sector 74A, Gurugram 122004, Haryana
            </p>
          </div>

          <div className='max-sm:flex-row max-sm:w-full max-sm:gap-20 w-[35%] flex flex-col gap-2'>
            <div className='flex flex-col gap-2'>
              <h4 className='text-lg text-white font-semibold'>Contact Us</h4>
              <ul className='w-full flex flex-col gap-2 lightgray'>
                <li className='w-full'>
                  <a href="tel:0124-5082257" className='max-sm:text-sm w-full flex'>0124-5082257</a>
                  <a href="mailto:info@g-eng.in" className='max-sm:text-sm w-full flex'>info@g-eng.in</a>
                </li>
              </ul>
            </div>
            
            <div className='max-sm:pt-0 flex flex-col items-start justify-start pt-4'>
              <div className='w-full'>
                <h4 className='w-full text-lg text-white font-semibold'>Quick Links</h4>
              </div>
              <div className='max-sm:mt-4 flex gap-2 mt-6'>
                <a href="https://facebook.com" className='footer-icons flex items-center justify-center'><img src={facebookicon} alt="Facebook" /></a>
                <a href="https://www.linkedin.com/company/saptagon-asia-private-ltd" className='footer-icons flex items-center justify-center'><img src={linkedinicon} alt="Linkedin" /></a>
              </div>
            </div>

          </div>

        </div>
      </div>

      <div className='px-4 md:px-20 flex flex-col gap-4 justify-center items-start py-6'>        
        <img src={linedivider} alt="" className='w-full pt-4 z-20'/>
        <div className='w-full flex max-sm:w-full justify-start sm:max-xl:justify-center items-center'>
          <p className='lightgray text-md max-xl:text-center'>2024 © All Rights Reserved. Website designed by <a href="https://aimantra.co">AiMantra.co</a></p>
        </div>
      </div>
    </div>
  )
}

export default Footer