import React, { useState } from 'react'
import AuthorityProjects from './Tabs/AuthorityProjects'
import {AllProjects} from '../Projects/Tabs/AllProjects'

const OngoingProjects = () => {
    const [toggleState, setToggleState] = useState(1);

  return (
        <div className='max-xl:py-10 relative flex items-start justify-center pt-20 pb-0'>
            <div className='max-sm:px-0 w-full flex flex-col items-center gap-12'>
                <div className='w-[100%] md:w-[60%] overflow-x-scroll border-[2px] md:pr-4 rounded-full'>
                    <div className="overflow-x-scroll max-sm:justify-start justify-center items-center flex gap-4 w-full md:w-auto">
                        <button
                            className={
                            toggleState === 1
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(1)}
                        >
                            All Projects
                        </button>
                        <button
                            className={
                            toggleState === 2
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(2)}
                        >
                            Authority
                        </button>
                        <button
                            className={
                            toggleState === 3
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(3)}
                        >
                            Detail Design
                        </button>
                        <button
                            className={
                            toggleState === 4
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(4)}
                        >
                            Detail Project Report
                        </button>
                        {/* <button
                            className={
                            toggleState === 5
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(5)}
                        >
                            Pre Engineering
                        </button>
                        <button
                            className={
                            toggleState === 6
                                ? "home-tabs home-active-tabs"
                                : "home-tabs"
                            }
                            onClick={() => setToggleState(6)}
                        >
                            Independent Engineering
                        </button> */}
                    </div>
                </div>
                <div className='filterservicebgright'></div>
                <div className='filterservicebgleft !top-[720px]'></div>
                <div className="w-full max-sm:flex-nowrap">
                    {toggleState === 1 ? <AuthorityProjects data={AllProjects}/> : null}
                    {toggleState === 2 ? <AuthorityProjects data={AllProjects} /> : null}
                    {/* {toggleState === 3 ? <AuthorityProjects data={designdata} /> : null}
                    {toggleState === 4 ? <AuthorityProjects data={dprdata} /> : null}
                    {toggleState === 5 ? <AuthorityProjects data={FRdata} /> : null}
                    {toggleState === 6 ? <AuthorityProjects data={Supervisiondata} /> : null}
                    {toggleState === 7 ? <AuthorityProjects data={OMdata} /> : null}
                    {toggleState === 8 ? <AuthorityProjects data={IEdata} /> : null} */}
                </div>
            </div>
        </div>
    )
}

export default OngoingProjects