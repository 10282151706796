import { MEDIA_URL } from "../../axios"
import achievementsbg from '../../Assets/Home/Achievementsbg.svg'
import cert1 from '../../Assets/Home/Cert1.png'
import cert2 from '../../Assets/Home/Cert2.png'

const Certificates = () => {
  const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

  return (
    <div className="flex flex-col items-center gap-12 max-sm:gap-10 h-[100vh] sm:max-xl:h-auto max-sm:h-auto my-4 ">
      <img src={achievementsbg} alt="" className='w-[50%] absolute -z-10 sm:max-xl:w-[70%] max-sm:hidden'/>
      <img src={aboutsplashbg} alt="About Us" className='max-sm:top-[12vh] max-sm:w-[58vw] sm:max-xl:top-[7vh] sm:max-xl:w-[30vw] max-sm:left-[25vw] w-[18vw] top-[16.5vh] absolute -z-10'/>
      <p className="max-sm:text-3xl text-4xl darkblue font-semibold">Our Certifications</p>
      
      <div className="max-sm:flex-col flex items-start text-center justify-center gap-44 sm:max-xl:gap-10 max-sm:gap-20 px-20 max-sm:px-10">

        <div className="max-sm:w-full sm:max-xl:w-full w-[30%] flex flex-col items-center gap-4 max-sm:gap-6">
          <img src={cert1} alt="Certificate1" className="max-sm:w-full sm:max-xl:w-full w-[90%]"/>
          <p className="max-sm:w-full w-[80%] text-xl font-semibold darkblue">Certificate of Compliance</p>
        </div>

        <div className="max-sm:w-full sm:max-xl:w-full w-[30%] flex flex-col justify-center items-center gap-4">
          <img src={cert2} alt="Certificate2" className="max-sm:w-full sm:max-xl:w-full w-[85%]"/>
          <p className="max-sm:w-full w-[80%] text-xl font-semibold darkblue">National Accreditation Board for 
          Testing and Calibration Laboratories</p>
        </div>
      </div>
    </div>
  )
}

export default Certificates