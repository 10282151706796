const AuthorityProjectsCards = (
  {
    img, name, scope, client, cost
  }
  ) => {

  const displayField = (label, value) => {
    if (value !== null && value !== undefined) {
      return (
        <p className='max-sm:text-sm darkgray text-base font-normal px-6'>
          <span className='font-semibold purple'>{label}: </span>{value}
        </p>
      );
    }
    return null;
  };

  return (
    <div className={`max-md:w-full max-xl:flex-[1_1_28%] cursor-pointer flex flex-col flex-[0_1_30%] mx-2 justify-start h-[62dvh] md:h-[60dvh] rounded-lg items-start gap-4 bg-white shadow-[0_5px_20px_0px_rgba(0,0,0,0.08)] hover:ease-in transition-all duration-300 hover:shadow-[0_5px_20px_0px_rgba(0,0,0,0.15)] border-b-8 border-[#8364C3]`}>
    {/* <div className={`max-md:w-full max-xl:flex-[1_1_30%] cursor-pointer flex flex-col flex-[0_1_30%] justify-start ${name?"sm:max-xl:h-[40vh] h-[57vh]":DetialProjectDescription?"sm:max-xl:h-[24vh] h-[30vh]":dprDescription?"sm:max-xl:h-[40vh] h-[35vh]":(FRProjectName || SupervisionProjectName)?"sm:max-xl:h-[42vh] h-[42vh]":(OMProjectName || IEProjectName)?"sm:max-xl:h-[46vh] h-[46vh]":"h-auto"} rounded-lg items-start gap-4 bg-white shadow-[0_5px_20px_0px_rgba(0,0,0,0.08)] hover:ease-in transition-all duration-300 hover:shadow-[0_5px_20px_0px_rgba(0,0,0,0.15)] border-b-8 border-[#8364C3]`}> */}
      <div className='flex flex-col items-start justify-start gap-2 bottom-0'>
        <div className="h-[32dvh] relative overflow-hidden mb-2 rounded-t-md">
          <img src={img} alt={name} className="object-cover"/>
        </div>
        {displayField("Name", name)}
        {displayField("Scope of Work", scope)}
        {displayField("Client", client)}
        {displayField("Cost", cost)}
        {/* {displayField("Consultancy Cost", consultancyCost)} */}
        {/* {displayField("Length", length)} */}
        {/* {displayField("Project Description", DetialProjectDescription)} */}
        {/* {displayField("Project Code", ProjectCode)} */}
        {/* {displayField("Description", dprDescription)} */}
        {/* {displayField("DPR Length", dprLength)} */}
        {/* {displayField("CA Amount", CAAmount)} */}
        {/* {displayField("DPR Client", dprClient)} */}
        {/* {displayField("Concerned Dealing", ConcernedDealing)} */}
        {/* {displayField("LOA Date", LoaDate)} */}
        {/* {displayField("Completion Period", CompletionPeriod)} */}
        {/* {displayField("Payment Stages", PaymentStages)} */}
        {/* {displayField("Stages Completed", StagesCompleted)} */}
        {/* {displayField("Bill Raised", BillRaised)} */}
        {/* {displayField("Billing", Billing)} */}
        {/* {displayField("EOT", EOT)} */}
        {/* Feasibility Report Projects */}
        {/* {displayField("Project Name", FRProjectName)}
        {displayField("Project Description", FRDescription)}
        {displayField("Client", FRClient)}
        {displayField("Cost", FRCost)} */}
        
        {/* Supervision Projects */}
        {/* {displayField("Project Name", SupervisionProjectName)}
        {displayField("Project Description", SupervisionDescription)}
        {displayField("Client", SupervisionClient)}
        {displayField("Cost", SupervisionCost)} */}
        
        {/* Operation Maintenance Projects */}
        {/* {displayField("Project Name", OMProjectName)}
        {displayField("Project Description", OMDescription)}
        {displayField("Client", OMClient)}
        {displayField("Cost", OMCost)} */}

        {/* Independent Engineer Projects */}
        {/* {displayField("Project Name", IEProjectName)}
        {displayField("Project Description", IEDescription)}
        {displayField("Client", IEClient)}
        {displayField("Cost", IECost)} */}
      </div>
    </div>
  )
}

export default AuthorityProjectsCards