import React from 'react'
import Hero from '../Components/About/Hero'
import OurTeam from '../Components/About/OurTeam'
import OurObjective from '../Components/About/OurObjective'
import OurVision from '../Components/About/OurVision'
import '../Styles/AboutUs.css'
import CoreValues from '../Components/About/CoreValues'
import Revenue from '../Components/About/Revenue'
import Getintouch from '../Components/About/Getintouch'

const About = () => {
  return (
    <main className='circularstd'>
      <Hero />
      <CoreValues/>
      <OurObjective />
      {/* <OurTeam /> */}
      <Revenue/>
      {/* <OurVision /> */}
      {/* <Getintouch/> */}
    </main>
  )
}

export default About