import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MEDIA_URL, MEDIA_URL2 } from '../../axios'
import submenuarrow from '../../Assets/Home/submenuarrow.svg'
import SectorMenu from './SectorMenu'
import logo from '../../Assets/Home/Logo.png'

const Navbar = () => {
  const hamicon = MEDIA_URL +'menu.svg';
  const hamcloseicon = MEDIA_URL +'menuclose.svg';
  const uparrow = MEDIA_URL2+'Sectormenu/uparrow.svg'
  const downarrow = MEDIA_URL2+'Sectormenu/downarrow.svg'
  const facebookicon = MEDIA_URL +'Footer/facebook.svg';
  const linkedinicon = MEDIA_URL +'Footer/linkedin.svg';
  // const logo = MEDIA_URL +'SeptagonLogo.svg';

  let timeoutId;
  const sidebarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isSectorMenuVisible, setIsSectorMenuVisible] = useState(false);

  const handleMouseEnter = () => {
    clearTimeout(timeoutId);
    setIsSectorMenuVisible(true);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setIsSectorMenuVisible(false);
    }, 100);
  };

  const handleScroll = () => {
    setIsSectorMenuVisible(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    

    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='z-20 relative overflow-hidden px-4 max-sm:py-6 w-full circularstd flex flex-col justify-center items-center gap-2 md:px-14 py-6'>
      
      {/* Main Navbar */}
      <div className='max-sm:justify-between max-sm:items-center w-full flex justify-between'>
        <div className='logo'>
          <NavLink to="/">
            <img
              className="max-sm:h-8 h-16 cursor-pointer hover:shadow-none"
              src={logo}
              alt="Septagon Logo"
            />
          </NavLink>
        </div>
        <div className='max-[1175px]:hidden flex'>
          <ul className='flex items-center gap-6 font-normal black'>
            <li>
              <NavLink to='/' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>Home</NavLink>
            </li>
            <li>
              <NavLink to='/About-us' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>About Us</NavLink>
            </li>
            <li>
              <NavLink to='/Projects' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>Projects</NavLink>
            </li>
            <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              <NavLink to='#' className={({ isActive }) => `${isActive? "p-2 flex items-center gap-2" : "p-2 flex items-center gap-2"}`}>Sectors {isSectorMenuVisible? <img src={uparrow} alt="Sector Menu" className='rotate-180 transition-all'/>:<img src={downarrow} alt="Sector Menu" className=' transition-all'/>}</NavLink>
            </li>
            <li>
              <NavLink to='/Lab' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>Lab & Machinery</NavLink>
            </li>
            <li>
              <NavLink to='/Certifications' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>Certifications</NavLink>
            </li>
            <li>
              <NavLink to='/Career' className={({ isActive }) => `${isActive ? "active-link p-2" : "p-2"}`}>Career</NavLink>
            </li>
            <li className='pl-10'>
              <NavLink to='/Contact-us'>
                <div className="transition-all rounded-full btn px-6 py-2 text-white">
                  Contact Us
                </div>    
              </NavLink>
            </li>
          </ul>
        </div>
        
        {/* Mobile Ham Menu */}
        <div className='md:hidden block'>
            {/* Hamburger Icon */}
            <button href='#' className="bg-transparent text-none p-4 max-[1175px]:block hidden" onClick={() => setIsOpen(!isOpen)}>
                {isOpen?<img src={hamcloseicon} alt="Menu" className='w-8'/>:<img src={hamicon} alt="Menu" className='w-8'/>}
            </button>

            {/* Menu Items  */}
            <div
              ref={sidebarRef} 
              className={
              isOpen?"overflow-y-auto shadow-[5px_10px_28px_0_rgba(0,0,0,0.15)] text-white rounded-tl-xl rounded-bl-xl fixed h-[calc(100%-5rem)] z-50 top-20 right-0 flex flex-col justify-between w-[80%] bg-[#222222] shadow-md max-[1175px]:flex hidden transition-all ease-in-out duration-500"
              :
              "shadow-[5px_20px_28px_0_rgba(0,0,0,0.15)] text-white fixed h-[calc(100%-5rem)] z-40 top-20 right-[-100%] flex flex-col justify-between w-[80%] bg-[#222222] shadow-md max-[1175px]:flex hidden transition-all ease-in-out duration-500"}>
                <div className='ham-links pt-4'>
                  <NavLink to="/" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 rounded-tl-xl active:bg-[#4FBECD] block px-6 py-4 text-xl text-whiteblack hover:bg-[#13ADCD] hover:text-white">Home</NavLink>
                  <NavLink to="/About-us" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">About Us</NavLink>
                  <NavLink to="/Projects" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">Projects</NavLink>
                  <div>
                    <button onClick={() => setIsSubMenuOpen(!isSubMenuOpen)} className="w-full flex items-center justify-between text-left md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">
                      Sector
                      {isSubMenuOpen? <img src={submenuarrow} alt="Sector Menu" className='max-md:w-3 w-5 rotate-180 transition-all'/>:<img src={submenuarrow} alt="Sector Menu" className='max-md:w-3 w-5 transition-all'/>}
                    </button>
                    {isSubMenuOpen && (
                      <div className='pl-4'>
                        <NavLink to="/Sectors/Roads-&-Bridges" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Roads & Bridges</NavLink>
                        <NavLink to="/Sectors/Tunnels" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Tunnels</NavLink>
                        <NavLink to="/Sectors/Transport-Planning" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Transport Planning</NavLink>
                        <NavLink to="/Sectors/Urban-Infrastructure" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Urban Infrastructure</NavLink>
                        <NavLink to="/Sectors/Railway" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Railway</NavLink>
                        <NavLink to="/Sectors/Expressway" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Expressway</NavLink>
                        <NavLink to="/Sectors/Metro" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Metro</NavLink>
                        <NavLink to="/Sectors/Waterways" onClick={() => { setIsOpen(false); setIsSubMenuOpen(false); }} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block border-b-2 px-6 py-4 text-xl text-[#fff] hover:bg-[#4FBECD] hover:text-white">Waterways</NavLink>
                        {/* Add more submenu items here as needed */}
                      </div>
                    )}
                  </div>
                  <NavLink to="/Lab" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">Lab & Machinery</NavLink>
                  <NavLink to="/Certifications" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">Certifications</NavLink>
                  <NavLink to="/Career" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">Career</NavLink>
                  <NavLink to="/Contact-us" onClick={() => setIsOpen(false)} className="md:text-2xl md:py-6 md:px-10 active:bg-[#4FBECD] block px-6 py-4 text-xl text-white hover:bg-[#4FBECD] hover:text-white">Contact</NavLink>
                </div>
              <div className='w-full md:py-6 p-2 px-6 flex justify-start items-center gap-6 border-t-[1px] bg-[#222222]'>
              <a href="https://facebook.com" className='p-4 rounded-full bg-[#61ACCA]'><img src={facebookicon} alt="Twitter" className='w-4'/></a>
              <a href="https://linkedin.com" className='p-4 rounded-full bg-[#61ACCA]'><img src={linkedinicon} alt="Instagram" className='w-4'/></a>
              </div>
            </div>
        </div>
            
      </div>
      {/* Sector Menu */}
      {isSectorMenuVisible && <SectorMenu isVisible={isSectorMenuVisible} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />}
    </div>
  )
}

export default Navbar