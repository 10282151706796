import credentialsbg from '../../Assets/Home/credentialsbg.svg'
import credentialsimg from '../../Assets/Home/credentialsimg.png'
import { MEDIA_URL } from '../../axios';

const Credentials = () => {
    const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

    return (
        <div className='relative flex flex-col items-center justify-center gap-8 h-[70vh] sm:max-xl:h-auto max-sm:h-auto mb-10 max-sm:my-24 sm:max-xl:my-28 max-sm:px-6'>
            <img src={credentialsbg} alt="About Us" className='w-full absolute z-0 '/>
            <img src={aboutsplashbg} alt="About Us" className='max-sm:-top-[2vh] max-sm:w-[58vw] max-sm:left-[25vw] sm:max-xl:-top-[2vh] sm:max-xl:left-[36vw] sm:max-xl:w-[30vw] w-[15vw] top-[5vh] absolute -z-10 '/>
            <p className='max-sm:text-3xl text-4xl font-semibold darkblue'>Our Credentials</p>
            <img src={credentialsimg} alt="Credentials" className='max-sm:w-full sm:max-xl:w-[85%] w-[65%]'/>
        </div>
    )
}

export default Credentials