import { useState } from 'react'
import ServicesBg from '../../Assets/Home/ServicesBg.png'
import ServicesTab from './Tabs/ServicesTab'
import splashblue from '../../Assets/Home/splashblue.svg'
import ourservices1 from '../../Assets/Home/ourservices1.svg'
import ourservices2 from '../../Assets/Home/ourservices2.svg'
import ourservices3 from '../../Assets/Home/ourservices3.svg'
import ourservices4 from '../../Assets/Home/ourservices4.svg'
import ourservices5 from '../../Assets/Home/ourservices5.svg'

const OurServices = () => {
    const [toggleState, setToggleState] = useState(1)
    const servicesicons = [ourservices1, ourservices2, ourservices3, ourservices4, ourservices5]
    const designdata = ["Pre Engineering","Feasibility","Preliminary Project Report","Detail Design","Detail Design Report (DPR)"]
    const supervisiondata = ["Project Management","Authority Engineer","Operations & Maintenance","Independent Engineering","Proof Check, Safety","Quality control and Audit"]
    
    const designServices = designdata.map((name, index) => ({
        name,
        img: servicesicons[index % servicesicons.length] // Ensure it loops if there are more names than icons
    }));
    
    const supervisionServices = supervisiondata.map((name, index) => ({
        name,
        img: servicesicons[index % servicesicons.length] // Ensure it loops if there are more names than icons
    }));
    return (
    <div className='relative z-10 flex flex-col items-center justify-start w-screen h-auto gap-0 md:h-screen bg-cover md:bg-contain bg-center bg-no-repeat my-16 md:my-0 py-10 md:py-16 md:pt-28' style={{backgroundImage:`url(${ServicesBg})`}}>
        <img src={splashblue} alt="" className='absolute w-[50dvw] md:w-[20vw] -z-20 md:top-[13%]'/>
        <p className='md:text-4xl text-2xl font-semibold -z-10'>Our Services</p>
        <div className="overflow-x-scroll overflow-y-hidden z-50 justify-center min-h-[6dvh] items-center text-md md:text-xl flex gap-10 md:gap-4 w-[90%] md:w-auto mt-10 md:mt-16">
            <button
                className={
                toggleState === 1
                    ? "text-white px-3 border-b-2 border-white py-2"
                    : "text-white px-3"
                }
                onClick={() => setToggleState(1)}
            >
                Design
            </button>
            <button
                className={
                toggleState === 2
                    ? "text-white px-3 border-b-2 border-white py-2"
                    : "text-white px-3"
                }
                onClick={() => setToggleState(2)}
            >
                Supervision
            </button>
        </div>
        <div className="w-full flex justify-center pt-10 md:pt-20">
            {toggleState === 1 ? <ServicesTab data={designServices}/> : null}
            {toggleState === 2 ? <ServicesTab data={supervisionServices}/> : null}
        </div>
    </div>
  )
}

export default OurServices