import Job1 from '../../Assets/Home/Job1.png'
import { MEDIA_URL2 } from '../../axios'


const PositionCards = ({data}) => {
    const locationicon = MEDIA_URL2+'Career/location.svg'
    const timeicon = MEDIA_URL2+'Career/time.svg'
    const experienceicon = MEDIA_URL2+'Career/experience.svg'

    const {position_name, job_type,location,experience,department_name,status,sub_company_name,description} = data
    return (
    <div className='flex flex-col items-start gap-4 px-2 py-4 border-[1px] rounded-xl cursor-pointer cicularstd duration-300'>
        <div className='rounded-xl'>
            <img src={Job1} alt={position_name} className='w-full'/>
        </div>
        <div className='flex flex-col items-start gap-4 px-2'>
            <p className='darkblue font-semibold text-2xl'>{position_name}</p>
            <div className="flex items-center gap-4 text-sm lightgray">
                <div className="flex items-center justify-center gap-1">
                    <img src={locationicon} alt={location} className='md:w-4 fill-[#9DA6AF]'/>
                    <div>{location}</div>
                </div>
                <div className="flex justify-center items-center gap-1">
                    <img src={timeicon} alt={job_type} className='md:w-4 fill-[#9DA6AF]'/>
                    <div>{status}</div>
                </div>
                <div className="flex justify-center items-center gap-1">
                    <img src={experienceicon} alt={experience} className='md:w-4 fill-[#9DA6AF]'/>
                    <div>{experience}</div>
                </div>
            </div>

            <div className="flex items-center gap-4 text-xs darkgray font-semibold">
                <p className="flex items-center justify-center gap-2 bg-[#EFF3FC] rounded-md p-3">
                    {department_name}
                </p>
                <p className="flex justify-center items-center gap-2 bg-[#EFF3FC] rounded-md p-3">
                    {sub_company_name}
                </p>
            </div>
            <p className='darkgray'>{description}</p>
        </div>
    </div>
  )
}

export default PositionCards