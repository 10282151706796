import React from 'react'
import SolutionCard from './SolutionCard'
// import { MEDIA_URL } from '../../axios'
import roadshighwayicon from '../../Assets/Home/roadshighway.svg'
import metrorailwayicon from '../../Assets/Home/metrorailwayicon.svg'
import tunnelbridgeicon from '../../Assets/Home/tunnelbridge.svg'
import urbaninfrastructureicon from '../../Assets/Home/urbaninfrastructure.svg'

const OurSolution = () => {
  // const servicecover = MEDIA_URL +'Home/servicecover.svg';

  const data=[{
    img:roadshighwayicon,
    name:"Roads, Highways & Expressways",
    description:"We present you a proposal and discuss niffty-gritty like",
  },{
    img:metrorailwayicon,
    name:"Railways, Metros & Hydro Projects",
    description:"We present you a proposal and discuss niffty-gritty like",
  },{
    img:tunnelbridgeicon,
    name:"Tunnel & Bridge",
    description:"We present you a proposal and discuss niffty-gritty like",
  },{
    img:urbaninfrastructureicon,
    name:"Urban Infrastructure & Smart City",
    description:"We present you a proposal and discuss niffty-gritty like",
  }]

  return (
    <div className='flex flex-col relative overflow-hidden'>
      <div className="oursolutionbg"></div>
      <div className='flex flex-col justify-center items-center py-10 gap-6 md:gap-10'>
        <div className='flex flex-col justify-center items-center gap-4'>
          {/* <p className='purple max-sm:text-sm font-semibold text-lg'>Our Solution</p> */}
          <p className='max-sm:w-[60%] text-2xl md:text-4xl black text-center font-semibold'>Area of <span className='purple'>Expertise</span></p>
          <p className='max-sm:w-[90%] text-sm md:text-md w-[60%] text-center darkgray pt-4'>
            Connecting India with precision. For a decade, we’ve excelled in infrastructure consultancy, completing 1500+ projects worth 30K Crores across roads, highways, railways, bridge and tunnels. Trust us to bridge gaps and build the future.  
          </p>
        </div>
        <div className='max-sm:flex-nowrap max-sm:w-full py-4 max-sm:overflow-x-auto w-[90%] flex justify-center max-sm:justify-start gap-6 flex-wrap'>
          {data.map((items, index)=>(
            <SolutionCard
            key={items.name+index}
            img={items.img}
            name={items.name}
            description={items.description}
            />
          ))}
        </div> 
      </div>
    </div>
  )
}

export default OurSolution