import React from 'react'
import FormSection from '../Components/Contact/FormSection'
import ContactDetails from '../Components/Contact/ContactDetails'

const Contact = () => {
  return (
    <div className='contactcont w-full flex flex-col items-center justify-center gap-12 pt-8 circularstd'>
        <div className="c-hero w-full relative flex items-center justify-center">
          <FormSection/>
        </div>
        <div className="c-mapsection w-full relative flex flex-col items-center justify-center">
          <ContactDetails/>
        </div>
    </div>
  )
}

export default Contact