import './index.css';
import React from 'react';
import App from './App';
import ReactDOM from 'react-dom/client';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import Home from './Pages/Home';
import About from './Pages/About';
import Projects from './Pages/Projects';
import Certifications from './Pages/Certifications';
import Contact from './Pages/Contact';
import AllProjectDetails from './Pages/Projects/AllProjectDetails';
// import SectorDetails from './Pages/Sector/SectorDetails';
import Lab from './Pages/Lab';
import Career from './Pages/Career';
import NewSectorDetails from './Pages/Sector/NewSectorDetails';


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="" element={<Home />} />
      <Route path="About-us" element={<About />} />
      <Route path="Projects/">
        <Route path="" element={<Projects/>} />
        <Route path="/Projects/:clientName" element={<AllProjectDetails/>} />
      </Route>
      <Route path="Certifications" element={<Certifications />} />
      <Route path="Sectors/">
        <Route path="" element={<>Sectors</>} />
        {/* <Route path="/Sectors/:name" element={<SectorDetails />} /> */}
        <Route path="/Sectors/:name" element={<NewSectorDetails />} />
      </Route>
      <Route path="Lab" element={<Lab />} />
      <Route path="Career" element={<Career />} />
      <Route path="Contact-us" element={<Contact />} />
    </Route>
  )
);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
