import React from 'react';
import { motion } from 'framer-motion'; // Import framer-motion
import Saptagoniconpoints from '../../Assets/Home/Saptagoniconpoints.png';
import RevenueGraph from '../../Assets/Home/RevenueGraph.png';
import Footprint from '../../Assets/Home/Footprint.png';
import { MEDIA_URL } from '../../axios';

const Revenue = () => {
  const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

  // Animation variants for images and list items
  const imageVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: 'spring', stiffness: 50, delay: 0.3 },
    },
  };

  const listItemVariant = {
    hidden: { opacity: 0, x: 40 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2 + 0.7, // Staggered delay for each list item
        type: 'spring',
        stiffness: 50,
      },
    }),
  };

  const locations = [
    'Jammu & Kashmir',
    'Punjab',
    'Rajasthan',
    'Maharashtra',
    'Odisha Assam',
    'Himachal',
    'Haryana',
    'Gujrat',
    'Karnataka',
    'Assam',
  ];

  return (
    <div className="my-10 px-14 max-sm:px-4 sm:max-xl:px-4">
      <div className='relative max-sm:w-full w-full max-sm:px-0 max-sm:gap-4 max-sm:pt-0 flex flex-col gap-4 justify-center items-center pt-4'>
        <motion.img
          src={aboutsplashbg}
          alt="About Us"
          className='max-sm:top-[5%] max-sm:w-[60vw] max-sm:left-[20%] w-[18vw] top-[4vh] right-[22vw] absolute -z-10'
          initial="hidden"
          animate="visible"
          variants={imageVariant}
        />
        <p className='purple font-semibold'>Revenue and Our Footprint</p>
        <p className='max-sm:w-full w-[50%] darkblue text-center md:text-4xl text-2xl font-semibold'>
          Transforming Landscapes <span className='purple'>Across India</span>
        </p>
        <div className='flex max-sm:flex-col gap-10 sm:max-xl:justify-center justify-around items-start md:mt-12 max-sm:px-4'>

          <motion.div
            className="max-sm:w-full w-[35%] flex flex-col items-center sm:max-xl:items-start gap-2 md:gap-4"
            initial="hidden"
            animate="visible"
            variants={imageVariant}
          >
            <img src={RevenueGraph} alt="Revenue Graph" className='mt-12 max-sm:mt-6 max-sm:w-full w-full'/>
            <div className='w-full flex items-center px-12 sm:max-xl:px-0 max-sm:px-0 gap-2 mt-6'>
              <img src={Saptagoniconpoints} alt="Saptagon" className='w-6'/>
              <p className='md:text-xl font-semibold darkblue'>Revenue</p>
            </div>
            <p className='max-sm:w-full w-[80%] text-sm md:text-lg darkblue'>
              Achieving <b>30% CAGR</b> over the last 5 years, we lead infrastructure consultancy with topnotch tech, cutting-edge machinery, and a stellar in-house team.
            </p>
          </motion.div>

          <div className="flex flex-col md:flex-row justify-center items-center md:items-start mt-4 md:mt-0 w-full xl:w-[50%] md:gap-10">
            <motion.img
              src={Footprint}
              alt="Our Footprint"
              className='md:mt-10 mt-6 w-[80%] md:w-[60%]'
              initial="hidden"
              animate="visible"
              variants={imageVariant}
            />
            <div className='w-full md:mt-10'>
              <div className='w-full flex items-center gap-2 my-4'>
                <img src={Saptagoniconpoints} alt="Saptagon" className='w-6'/>
                <p className='md:text-lg font-semibold darkblue'>Our Footprint</p>
              </div>
              <div className="w-full text-sm md:text-lg flex flex-col max-sm:flex-row max-sm:justify-start gap-10 justify-between md:gap-0">
                {/* First Column of Locations */}
                <ul>
                  {locations.slice(0, 5).map((location, index) => (
                    <motion.li
                      key={index}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                      variants={listItemVariant}
                      className="darkblue"
                    >
                      ⦿ {location}
                    </motion.li>
                  ))}
                </ul>

                {/* Second Column of Locations */}
                <ul>
                  {locations.slice(5, 10).map((location, index) => (
                    <motion.li
                      key={index + 5}
                      initial="hidden"
                      animate="visible"
                      custom={index + 5}
                      variants={listItemVariant}
                      className="darkblue"
                    >
                      ⦿ {location}
                    </motion.li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Revenue;
