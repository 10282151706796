import projectimg1 from '../../../Assets/Home/Projects/1.png'
import projectimg2 from '../../../Assets/Home/Projects/2.png'
import projectimg3 from '../../../Assets/Home/Projects/3.png'
import projectimg4 from '../../../Assets/Home/Projects/4.png'
import projectimg5 from '../../../Assets/Home/Projects/5.png'
import projectimg6 from '../../../Assets/Home/Projects/6.png'
import projectimg7 from '../../../Assets/Home/Projects/7.png'
import projectimg8 from '../../../Assets/Home/Projects/8.png'
import projectimg9 from '../../../Assets/Home/Projects/9.png'
import projectimg10 from '../../../Assets/Home/Projects/10.png'
import projectimg11 from '../../../Assets/Home/Projects/11.png'
import projectimg12 from '../../../Assets/Home/Projects/12.png'
import projectimg13 from '../../../Assets/Home/Projects/13.png'
import projectimg14 from '../../../Assets/Home/Projects/14.png'
import projectimg15 from '../../../Assets/Home/Projects/15.png'
import projectimg16 from '../../../Assets/Home/Projects/16.png'
import projectimg17 from '../../../Assets/Home/Projects/17.png'

export const AllProjects = [
    {
      Sr: 1,
      img: projectimg1,
      NameOfProject: "Delhi Jaipur Expressway",
      ScopeofWork: "Feasibility Report",
      Client: "NHAI",
      Cost: "6,000.00 Cr.",
    },
    {
      Sr: 2,
      img: projectimg2,
      NameOfProject: "Gohana - Lakhan Majra - Bhiwani Road (Rohtak)",
      ScopeofWork: "Supervision",
      Client: "PWD HARYANA",
      Cost: "104.19 Cr.",
    },
    {
      Sr: 3,
      img: projectimg3,
      NameOfProject:"Padhampur to Bikaner / Durgargarh to Rajgarh / Hanumangarh to Abhor (Rajasthan)",
      ScopeofWork: "Feasibility Report",
      Client: "PWD, Rajasthan",
      Cost: null
    },
    {
      Sr: 4,
      img: projectimg4,
      NameOfProject: "Gurgaon-Faridabad, Ballabhgarh-Sohna Road",
      ScopeofWork: "Operations and Maintainnace",
      Client: "PWD B&R Branch, Gurgaon",
      Cost: "180.00 Cr.",
    },
    {
      Sr: 5,
      img: projectimg5,
      NameOfProject: "KMP (Kundali,Manesar,Palwal) Expressway",
      ScopeofWork: "Detailed Project Report",
      Client: "HSIIDC",
      Cost: "1,956.17 Cr.",
    },
    {
      Sr: 6,
      img: projectimg6,
      NameOfProject: "Hisar to Dabwali Section on NH10 (Pkg 1)",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "549.00 Cr.",
    },
    {
      Sr: 7,
      img: projectimg7,
      NameOfProject: "Hisar to Dabwali Section on NH10 (Pkg 2)",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "648.00 Cr.",
    },
    {
      Sr: 8,
      img: projectimg8,
      NameOfProject:"Sangrur Bypass to Tapa section | Tapa to Bathinda Section on NH607",
      ScopeofWork: "Authority Engineer",
      Client: "PWD B&R, Bathinda",
      Cost: "804.78 Cr.",
    },
    {
      Sr: 9,
      img: projectimg9,
      NameOfProject:
        "Nhavra- Namgaon- Kasti-Shrigonda-Adhalgaon-Jalgaon- Jamkhed, SH-55 (Pkg No. Nashik 4)",
      ScopeofWork: "Detailed Project Report",
      Client: "PWD Maharashtra",
      Cost: "3.25 Cr.",
    },
    {
      Sr: 10,
      img: projectimg10,
      NameOfProject: "Dwarka Expressway",
      ScopeofWork: "Feasibility Report",
      Client: "NHAI",
      Cost: "5,887.30 Cr.",
    },
    {
      Sr: 11,
      img: projectimg11,
      NameOfProject: "Pilibhit-Puranpur Section on NH730",
      ScopeofWork: "Authority Engineer",
      Client: "PWD Uttar Pradesh",
      Cost: "105.90 Cr.",
    },
    {
      Sr: 12,
      img: projectimg12,
      NameOfProject: "Jind Karnal Section on NH352",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "198.91 Cr.",
    },
    {
      Sr: 13,
      img: projectimg13,
      NameOfProject: "IMT Kharkhoda",
      ScopeofWork: "DPR",
      Client: "HSIIDC",
      Cost: "415.10 Cr.",
    },
    {
      Sr: 14,
      img: projectimg14,
      NameOfProject: "Jind - Gohana Section on NH352A",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "198.05 Cr.",
    },
    {
      Sr: 15,
      img: projectimg15,
      NameOfProject: "Bhiwani Mundal- Jind Road on NH709A",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "275.12 Cr.",
    },
    {
      Sr: 16,
      img: projectimg16,
      NameOfProject: "Gandhinagar - Ambaji Road on SH71 (Gujrat)",
      ScopeofWork: "Authority Engineer",
      Client: "R&B Mehsana Gujarat",
      Cost: "75.89 Cr.",
    },
    {
      Sr: 17,
      img: projectimg17,
      NameOfProject: "Narnaul Bypass | Ateli Mandi to Narnaul Section at NH11",
      ScopeofWork: "Independent Engineer",
      Client: "NHAI",
      Cost: "952.11 Cr.",
    },
    {
      Sr: 18,
      img: projectimg1,
      NameOfProject: "Indore - Gujrat/MP Boarder on NH 47",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "210.00 Cr.",
    },
    {
      Sr: 19,
      img: projectimg2,
      NameOfProject: "Ujjain - Jhalawar (Upto Rajasthan Border) on NH552G",
      ScopeofWork: "Authority Engineer",
      Client: "NHAI",
      Cost: "376.78 Cr.",
    },
];
