import Certificates from '../Components/Achivements/Certificates'
import Credentials from '../Components/Achivements/Credentials'

const Certifications = () => {
  return (
    <div className='circularstd'>
      <Certificates/>
      <Credentials/>
    </div>
  )
}

export default Certifications