import React, { useState } from 'react'
import AuthorityProjectsCards from './AuthorityProjectsCards'

const AuthorityProjects = ({data}) => {  
  
  const projectsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastProject = currentPage * projectsPerPage;
  const totalPages = Math.ceil(data.length / projectsPerPage);
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = data.slice(indexOfFirstProject, indexOfLastProject);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`mx-[3px] page-number ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };

  return (
    <div className='w-full flex-col justify-center items-center max-sm:pl-0 md:pl-0'>
      <div className='max-md:flex-col w-full flex flex-wrap flex md:justify-center justify-start items-center gap-6 max-sm:mb-10 mb-20'>
      {currentProjects.map((items, index)=>(
            <AuthorityProjectsCards
            key={items.name+index}
            // Authority Projects
            img={items.img}
            name={items.NameOfProject}
            scope={items.ScopeofWork}
            client={items.Client}
            cost={items.Cost}
            // // Detail Design Projects
            // DetialProjectDescription={items.DetialProjectDescription}
            // // DPR Projects
            // ProjectCode={items.ProjectCode}
            // dprDescription={items.dprDescription}
            // dprLength={items.dprLength}
            // CAAmount={items.CAAmount}
            // dprClient={items.dprClient}
            // ConcernedDealing={items.ConcernedDealing}
            // LoaDate={items.LoaDate}
            // CompletionPeriod={items.CompletionPeriod}
            // PaymentStages={items.PaymentStages}
            // StagesCompleted={items.StagesCompleted}
            // BillRaised={items.BillRaised}
            // Billing={items.Billing}
            // EOT={items.EOT}
            // // Feasibility Report Projects
            // FRProjectName={items.FRProjectName}
            // FRDescription={items.FRDescription}
            // FRClient={items.FRClient}
            // FRCost={items.FRCost}
            // // Supervision Projects
            // SupervisionProjectName={items.SupervisionProjectName}
            // SupervisionDescription={items.SupervisionDescription}
            // SupervisionClient={items.SupervisionClient}
            // SupervisionCost={items.SupervisionCost}
            // // Operation Maintenance Projects
            // OMProjectName={items.OMProjectName}
            // OMDescription={items.OMDescription}
            // OMClient={items.OMClient}
            // OMCost={items.OMCost}
            // // Independent Engineer Projects
            // IEProjectName={items.IEProjectName}
            // IEDescription={items.IEDescription}
            // IEClient={items.IEClient}
            // IECost={items.IECost}
            />
        ))}
      </div>
      <div className='pagination'>
        <button onClick={handlePrevPage} disabled={currentPage === 1} className='page-arrow mr-4'>
          &lt;
        </button>
        {renderPageNumbers()}
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className='page-arrow ml-4'>
          &gt;
        </button>
      </div>
    </div>
  )
}

export default AuthorityProjects