import React from 'react'
import { MEDIA_URL } from '../../axios'

const ContactDetails = () => {
  const callicon = MEDIA_URL +'Contact/call.svg';
  const emailicon = MEDIA_URL +'Contact/email.svg';
  const locationicon = MEDIA_URL +'Contact/location.svg';

  return (
    <div className='max-sm:px-12 max-xl:gap-10 max-sm:flex-col max-sm:items-start max-sm:py-12 w-full flex gap-20 justify-center items-center px-30 py-24'>
      <div className='max-sm:w-full bg-white w-[25%] flex items-center gap-4 p-4 shadow-[0_0_15px_5px_rgba(0,0,0,0.04)] hover:shadow-[0_0_15px_5px_rgba(0,0,0,0.08)] duration-300 cursor-pointer rounded-xl'>
        <img src={callicon} alt="Call Us" className='h-10'/>
        <div className='flex flex-col gap-0'>
          <p className='max-sm:text-lg purple text-xl font-semibold'>Call</p>
          <p className='max-sm:text-sm black text-md'><a href="tel:0124-5082257">0124-5082257</a></p>
        </div>
      </div>
      <div className='max-sm:w-full bg-white w-[25%] flex items-center gap-4 p-4 shadow-[0_0_15px_5px_rgba(0,0,0,0.04)] hover:shadow-[0_0_15px_5px_rgba(0,0,0,0.08)] duration-300 cursor-pointer rounded-xl'>
        <img src={emailicon} alt="Email Us" className='h-10'/>
        <div className='flex flex-col gap-0'>
          <p className='purple max-sm:text-lg text-xl font-semibold'>Email</p>
          <p className='black max-sm:text-sm text-md'><a href="mailto:info@g-eng.in">info@g-eng.in</a></p>
        </div>
      </div>
      <div className='max-sm:w-full bg-white w-[25%] flex items-center gap-4 p-4 shadow-[0_0_15px_5px_rgba(0,0,0,0.04)] hover:shadow-[0_0_15px_5px_rgba(0,0,0,0.08)] duration-300 cursor-pointer rounded-xl'>
        <img src={locationicon} alt="Location" className='h-10'/>
        <div className='flex flex-col gap-0'>
          <p className='purple text-lg md:text-xl font-semibold'>Location</p>
          <p className='black text-sm md:text-md'>DCG4 - 1708, Tower 4, DLF Corporate Greens, Sector 74A, Gurugram 122004, Haryana</p>
        </div>
      </div>

    </div>
  )
}

export default ContactDetails