import React from 'react'
import { NavLink } from 'react-router-dom'
import { MEDIA_URL } from '../../axios'
import officeimg from '../../Assets/Home/saptagon website images/irina-blok-QwmB-VgL7vs-unsplash.jpg'
import officeimg2 from '../../Assets/Home/saptagon website images/jamar-penny-ZgmGq_eFmUs-unsplash.jpg'
import officeimg3 from '../../Assets/Home/saptagon website images/shawn-rain-wOAW1Hnk9Tw-unsplash.jpg'
import officeimg4 from '../../Assets/Home/saptagon website images/zoshua-colah-OonZSa_ME6s-unsplash.jpg'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Aboutus = () => {
  const morearrow = MEDIA_URL+'Home/more-arrow.svg';
  const officeimgs = [
    {
      img:officeimg
    },{
      img:officeimg2
    },{
      img:officeimg3
    },{
      img:officeimg4
    },
  ]
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear"
  };

  return (
    <div className='max-lg:h-auto flex max-sm:flex-col justify-between max-sm:justify-center max-sm:gap-10 md:max-xl:h-[35vh] relative h-[70vh]'>
    {/* Slider Component */}
    <div className='homeaboutus max-xl:hidden slider-container slide-in' style={{ width: "45%", margin: "10px 0 0 55px" }}>
      <Slider {...settings}>
        {officeimgs.map((item, i) => (
          <img src={item.img} alt="Saptagon Office" className='h-[55vh] object-cover rounded-lg' key={i} />
        ))}
      </Slider>
    </div>

    {/* Tablet Component*/}
    <div className='homeaboutus max-sm:hidden xl:hidden slider-container slide-in' style={{ width: "45%", margin: "50px 0 0 0" }}>
      <Slider {...settings}>
        {officeimgs.map((item, i) => (
          <img src={item.img} alt="Saptagon Office" className='h-[50vh] object-cover rounded-lg' key={i} />
        ))}
      </Slider>
    </div>

    {/* Mobile Component*/}
    <div className='homeaboutus sm:hidden slider-container slide-in' style={{ width: "100%", margin: "50px 0 0 0" }}>
      <Slider {...settings}>
        {officeimgs.map((item, i) => (
          <img src={item.img} alt="Saptagon Office" className='h-[30vh] object-cover rounded-lg' key={i} />
        ))}
      </Slider>
    </div>

    {/* Content Section */}
    <div className='max-lg:px-6 max-lg:w-full sm:max-xl:w-[52%] w-[48%] max-lg:gap-4 sm:max-xl:gap-4 flex flex-col gap-6 justify-start items-start pt-6 z-10 fade-in'>

    <span className='grace purple text-xl'>About Us</span>
      {/* <p className='purple font-semibold fade-in'>About Us</p> */}
      <p className='darkblue text-2xl md:text-5xl font-semibold fade-in'>
        Pioneering Infrastructure <span className='purple'>Solutions</span>
      </p>
      <p className="max-sm:w-full sm:max-xl:text-justify sm:max-xl:w-[90%] w-[92%] darkgray text-sm md:text-md fade-in" style={{textAlign: "justify"}}>
        Founded over a decade ago, Saptagon Asia Pvt Ltd (formerly G Eng Advisory Pvt Ltd) is a pioneering consultancy 
        specializing in design, supervision, safety, and NABL accredited lab and machinery services. With a strong team 
        of 500+ professionals, we lead the transformation of transportation infrastructure. From inception to completion, 
        we ensure excellence in design and construction supervision, prioritizing stringent safety standards. Our commitment 
        to quality and sustainability defines our role as industry leaders in shaping the future of infrastructure development.
      </p>
      <NavLink to='/'>
        <div className="inline-flex gap-2 btn btn-hover text-sm md:text-md transition-all rounded-full px-6 py-[12px] text-white">
          More Details
          <img src={morearrow} alt="More Details" />
        </div>
      </NavLink>
    </div>
  </div>
  )
}

export default Aboutus