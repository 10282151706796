import Hero from '../Components/Home/Hero'
import Aboutus from '../Components/Home/Aboutus'
import Stats from '../Components/Home/Stats'
import OurSolution from '../Components/Home/OurSolution'
import FilterServices from '../Components/Home/FilterServices'
// import Testimonials from '../Components/Home/Testimonials'
import OurClients from '../Components/Home/OurClients'
import '../Styles/Home.css'
import OurServices from '../Components/Home/OurServices'

const Home = () => {
  return (
    <main className='circularstd'>
        <Hero />
        <Aboutus />
        <Stats />
        <OurSolution />
        <FilterServices/>
        {/* <Testimonials /> */}
        <OurServices/>
        <OurClients/>
    </main>
  )
}

export default Home