import React from 'react'
import '../Styles/Project.css'
import ProjectStatus from '../Components/Projects/ProjectStatus'

const Projects = () => {
  return (
    <main className='circularstd overflow-hidden'>
      <ProjectStatus />
    </main>
  )
}

export default Projects