import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderrightarrow from '../../Assets/Home/SliderRightArrow.svg'
import sliderleftarrow from '../../Assets/Home/SliderLeftArrow.svg'
import client1 from '../../Assets/Home/ClientNHAI.png'
import client2 from '../../Assets/Home/Client2.png'
import client3 from '../../Assets/Home/ClientMSV.png'
import client4 from '../../Assets/Home/ClientPWD.png'
import client5 from '../../Assets/Home/Client5.png'
import client6 from '../../Assets/Home/ClientRTH.png'
import client7 from '../../Assets/Home/ClientNHIDCL.png'
import client8 from '../../Assets/Home/ClientMSRDC.png'
import client9 from '../../Assets/Home/ClientICT.png'
import client10 from '../../Assets/Home/ClientHSIIDC.png'
import client11 from '../../Assets/Home/ClientADANI.png'
import client12 from '../../Assets/Home/ClientGHV.png'
import client13 from '../../Assets/Home/ClientMCG.png'
import client14 from '../../Assets/Home/ClientSADBHAV.png'
import client15 from '../../Assets/Home/ClientARCONS.png'
import client16 from '../../Assets/Home/ClientGMDA.png'
import client17 from '../../Assets/Home/ClientLT.png'
import client18 from '../../Assets/Home/ClientCDS.png'
import client19 from '../../Assets/Home/ClientCPWD.png'
import client20 from '../../Assets/Home/ClientAPCO.png'
import client21 from '../../Assets/Home/ClientGAWAR.png'
import client22 from '../../Assets/Home/ClientFP.png'
import client23 from '../../Assets/Home/ClientALFAR.png'


function RightArrow(props){
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "transparent"}}
      onClick={onClick}
    >
      <img src={sliderrightarrow} alt="Prev" style={{ width: "30px", height: "30px" }} />
      </div>
  );
}

function LeftArrow(props){
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none", background: "transparent"}}
      onClick={onClick}
    >
      <img src={sliderleftarrow} alt="Prev" style={{ width: "30px", height: "30px" }} />
      </div>
  );
}


const OurClients = () => {
  const clients = [
    {
      img: client1
    },
    {
      img: client2
    },
    {
      img: client3
    },
    {
      img: client4
    },
    {
      img: client5
    },
    {
      img: client6
    },
    {
      img: client7
    },
    {
      img: client8
    },
    {
      img: client9
    },
    {
      img: client10
    },
    {
      img: client11
    },
    {
      img: client12
    },
    {
      img: client13
    },
    {
      img: client14
    },
    {
      img: client15
    },
    {
      img: client16
    },
    {
      img: client17
    },
    {
      img: client18
    },
    {
      img: client19
    },
    {
      img: client20
    },
    {
      img: client21
    },
    {
      img: client22
    },
    {
      img: client23
    }
  ];
  
  var settings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: 'linear',
    pauseOnHover: true,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 312,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  };

  return (
    <>
    <div className='max-sm:hidden ourclients2 overflow-hidden relative items-center justify-center gap-2 py-14 mb-10 md:mb-20'>
      <p className="purple text-center font-semibold text-md">Our Clients</p>
      <p className="darkblue text-center text-2xl md:text-3xl mt-4 font-semibold">Trusted by over <span className='purple'>150+ Companies</span> including</p>
      <div className='clientsbg'></div>
      <div className="slider-container px-20 mt-10 md:mt-16">
        <Slider {...settings}>
          {clients.map((items) => (
              <div>
                  <div className="flex items-center justify-center max-[1024px]:h-[10vh] h-[20vh]">
                      <img src={items.img} alt={items.name} className={`px-4 h-24 object-contain`}/>
                  </div>
              </div>
          ))}
        </Slider>
      </div>
    </div>

    {/* Mobile Component */}
    <div className="sm:hidden ourclients pb-20 mt-10 mb-4">
      <p className="purple text-center font-semibold text-md px-10">Our Clients</p>
      <p className="darkblue text-center text-2xl mt-4 font-semibold px-12">Trusted by over <span className='purple'>150+ Companies</span> including</p>
      {/* <div className='clientsbg'></div> */}
      <div className="slider-container mt-10">
            <Slider {...settings}>
            {clients.map((items) => (
                <div>
                    <div className="flex items-center justify-center h-auto">
                        <img src={items.img} alt={items.name} className={`px-2 h-16 object-contain`}/>
                    </div>
                </div>
            ))}
            </Slider>
        </div>
    </div>
  </>
  )
}

export default OurClients