 import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PositionSkeleton = ({cards}) => {
  return (
    Array(cards).fill(0).map((_,i)=>(
        <div
        className="flex md:flex-col items-start gap-4 px-2 py-4 border-[1px] rounded-xl cursor-pointer duration-300"
        key={i}
      >
        {/* Image Skeleton */}
        <div className="rounded-xl w-full md:w-auto">
          <Skeleton height={250} width={410} />
        </div>

        {/* Text and Details Skeleton */}
        <div className="flex flex-col md:flex-col items-start gap-4 px-2 w-full">
          {/* Position Name Skeleton */}
          <Skeleton width={200} height={30} className="darkblue" />

          {/* Location, Job Type, Experience Skeleton */}
          <div className="flex items-center gap-4 text-sm">
            <div className="flex items-center justify-center gap-1">
              <Skeleton width={10} height={10} circle />
              <Skeleton width={80} height={10} />
            </div>
            <div className="flex justify-center items-center gap-1">
              <Skeleton width={10} height={10} circle />
              <Skeleton width={80} height={10} />
            </div>
            <div className="flex justify-center items-center gap-1">
              <Skeleton width={10} height={10} circle />
              <Skeleton width={80} height={10} />
            </div>
          </div>

          {/* Department and Sub-Company Skeleton */}
          <div className="flex items-center gap-4 text-xs">
            <Skeleton width={100} height={20} />
            <Skeleton width={100} height={20} />
          </div>

          {/* Description Skeleton */}
          <Skeleton width={410} height={20} />
          <Skeleton width={410} height={20} />
        </div>
      </div>
    ))
  )
}

export default PositionSkeleton