import React, { useState } from 'react'
import { API, MEDIA_URL } from '../../axios';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const FormSection = () => {
    const hicon = MEDIA_URL +'Contact/hand.png' ;
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        message: '',
        subject: '',
        form_type: "contact us",
    });

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await API.post(`/website/contactform/`, formData);

            if (response.status === 200) {
                toast.success("Thank you! We'll get back to you soon.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                });

                setFormData({
                    name: '',
                    mobile: '',
                    email: '',
                    message: '',
                    subject: '',
                    form_type: "Contact Us - Saptagon"
                });
            }
            else {
                toast.error("Failed to send. Check your connection and retry.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                });
            }
        } 
        catch (error) {
            toast.error("Error! Unable to send. Try again later.", {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    };


  return (
    <div className='max-sm:gap-2 max-sm:w-[90%] md:max-xl:w-[90%] w-[70%] relative flex flex-col items-center justify-center gap-8'>
        <ToastContainer
            newestOnTop
            draggable
            rtl={false}
            theme="light"
            autoClose={1500}
            position="top-center"
            pauseOnFocusLoss={false}
        />
        <div className="max-sm:text-3xl flex flex-col gap-2 text-4xl text-center">
            <div className='text-center flex items-end gap-2 font-bold'>
                Hi,
                <img src={hicon} alt="Contact us" className='max-sm:w-[20%] '/>
                we are
            </div>
            <div className="blue font-bold">SAPTAGON</div>
        </div>

        <form onSubmit={handleSubmit} className="max-sm:w-[95%] md:max-xl:w-[80%] w-[60%] mt-10 py-10 px-8 bg-white rounded-xl flex flex-col gap-4 shadow-[0_0_32px_0_rgba(0,0,0,0.07)]">
            <div className="max-sm:gap-4 max-sm:flex-col flex items-center gap-12">
                <div className='w-full flex flex-col gap-2'>
                    <label htmlFor="email" className="text-sm font-medium ">
                        Email address
                    </label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        required
                        placeholder='Enter your email id'
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full px-4 py-2 bg-white border border-[#EDEEF1] rounded-md focus:border-[#8364C3] focus:ring-[#4FBECD] focus:outline-none focus:ring-1"
                    />
                </div>
                <div className='w-full flex flex-col gap-2'>
                    <label htmlFor="name" className="text-sm font-medium text-gray-700">
                    Full Name
                    </label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        required
                        placeholder='Enter your Full Name'
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full px-4 py-2 bg-white border border-[#EDEEF1] rounded-md focus:border-[#8364C3] focus:ring-[#4FBECD] focus:outline-none focus:ring-1"
                    />
                </div>
            </div>
            <div className="max-sm:gap-4 max-sm:flex-col flex items-center gap-12">
                <div className='w-full flex flex-col gap-2'>
                    <label htmlFor="mobile" className="text-sm font-medium text-gray-700">
                        Contact no.
                    </label>
                    <input
                        type="number"
                        id="mobile"
                        name="mobile"
                        required
                        placeholder='Enter your Contact no.'
                        value={formData.mobile}
                        onChange={handleChange}
                        className="w-full px-4 py-2 bg-white border border-[#EDEEF1] rounded-md focus:border-[#8364C3] focus:ring-[#4FBECD] focus:outline-none focus:ring-1"
                    />
                </div>
                <div className='w-full flex flex-col gap-2'>
                    <label htmlFor="destination" className="text-sm font-medium text-gray-700">
                        Subject
                    </label>
                    <input
                        type="text"
                        id="subject"
                        name="subject"
                        required
                        placeholder='Enter Your Subject'
                        value={formData.subject}
                        onChange={handleChange}
                        className="w-full px-4 py-2 bg-white border border-[#EDEEF1] rounded-md focus:border-[#8364C3] focus:ring-[#4FBECD] focus:outline-none focus:ring-1"
                    />
                   
                </div>
            </div>

            <div className="flex flex-col items-start">
                <label htmlFor="message" className="text-sm font-medium text-gray-700">
                    Message
                </label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="max-sm:min-h-[20vh] min-h-[25vh] resize-none w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md focus:border-[#8364C3] focus:ring-[#4FBECD] focus:outline-none focus:ring-1"
                />
            </div>
            <div className='flex items-center justify-center'>
                <button type='submit' className="px-6 py-2 mt-6 duration-500 text-white btn rounded-md focus:outline-none">
                    Send Message
                </button>
            </div>
        </form>
    </div>
  )
}

export default FormSection;