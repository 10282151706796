const ServicesTab = ({data}) => {
  return (
    <div className='w-[90%] flex flex-col md:flex-row md:gap-0 gap-10 items-center justify-around'>
        {data.map((item,i)=>(
            <div className='flex flex-col items-center gap-2'>
                <div className='w-20 h-20 md:w-24 md:h-24 flex items-center justify-center rounded-full border-2 border-white'><img src={item.img} alt={item.name} /></div>
                {/* <img src={} alt="" className='rounded-full border-2 border-white'/> */}
                <p className='text-white text-md md:text-lg'>{item.name}</p>
            </div>
        ))}
    </div>
  )
}

export default ServicesTab