import React from 'react';
import { motion } from 'framer-motion';
import curvedlines from '../../Assets/Home/curvedlines.svg';
import { MEDIA_URL } from '../../axios';

const CoreValues = () => {
  const items = [
    {
      title: 'Excellence',
      description: 'We strive for excellence in everything we do, delivering high-quality solutions that exceed expectations.',
    },
    {
      title: 'Integrity',
      description: 'Integrity is at the heart of our ethical standards, building trust with client and stakeholders.',
    },
    {
      title: 'Innovation',
      description: 'We foster a culture of innovation, continuously seeking new ideas and approaches to drive progress.',
    },
    {
      title: 'Client-Centric',
      description: "We are dedicated to understanding and meeting our client's needs by prioritizing client satisfaction",
    },
  ];

  const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

  // Animation variants
  const circleVariant = {
    hidden: { opacity: 0, scale: 0.5, x: -100 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        delay: i * 0.4, // delay for each circle
        type: 'spring',
        stiffness: 100,
      },
    }),
  };

  const descriptionVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.8 + i * 0.4, // delay to show after circles
        type: 'spring',
        stiffness: 50,
      },
    }),
  };

  return (
    <div className="px-14 m-28 max-sm:px-0 relative">
      <img
        src={aboutsplashbg}
        alt="About Us"
        className="max-sm:top-[6%] max-sm:w-[60vw] max-sm:left-[20%] sm:max-xl:top-[5.5vh] sm:max-xl:right-[35vw] sm:max-xl:w-[27vw] w-[20vw] top-[3vh] right-[25vw] absolute -z-10"
      />
      <div className="max-sm:w-full w-full max-sm:px-2 max-sm:gap-4 max-sm:pt-0 flex flex-col gap-4 justify-center items-center pt-4">
        <p className="purple font-semibold">Core Values</p>
        <p className="max-sm:w-[100%] w-[50%] darkblue text-center text-2xl xl:text-4xl font-semibold">
          Trust is vitally important <span className="purple">value that every</span> organization should have
        </p>
        <div className="flex flex-col gap-14 items-center relative w-screen h-auto md:mt-20 mt-10 px-4">
          <img
            src={curvedlines}
            alt="Curved Lines"
            className="hidden md:block -z-10 absolute md:w-[60%] md:left-[13%] md:top-[12%] bg-cover bg-no-repeat"
          />

          {/* Upper Row */}
          <div className="flex md:flex-row flex-col items-center justify-start md:gap-56 gap-10 md:px-36">
            {items.slice(0, 2).map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                custom={index}
                variants={circleVariant}
                className="md:w-[35%] flex items-center gap-6"
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={circleVariant}
                  className="flex items-center justify-center md:text-md text-sm font-semibold text-white rounded-full min-w-20 min-h-20 md:min-w-28 md:min-h-28 bg-[#8364C3] shadow-lg"
                >
                  <p className="w-full text-center">{item.title}</p>
                </motion.div>
                <motion.p
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={descriptionVariant}
                  className="text-sm md:text-lg w-30"
                  style={{textAlign: 'justify'}}
                >
                  {item.description}
                </motion.p>
              </motion.div>
            ))}
          </div>

          {/* Lower Row */}
          <div className="flex md:flex-row flex-col items-center justify-end md:gap-36 gap-10 px-0">
            {items.slice(2, 4).map((item, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                custom={index + 2} // Continue index for staggered animation
                variants={circleVariant}
                className="md:w-[33%] flex items-center gap-6"
              >
                <motion.div
                  initial="hidden"
                  animate="visible"
                  custom={index + 2}
                  variants={circleVariant}
                  className="flex items-center justify-center md:text-md text-sm font-semibold text-white rounded-full min-w-20 min-h-20 md:min-w-28 md:min-h-28 bg-[#8364C3] shadow-lg"
                >
                  <p className="w-full text-center">{item.title}</p>
                </motion.div>
                <motion.p
                  initial="hidden"
                  animate="visible"
                  custom={index + 2}
                  variants={descriptionVariant}
                  className="text-sm md:text-lg"
                  style={{textAlign: 'justify', width: "25vw", marginLeft: "50px"}}
                >
                  {item.description}
                </motion.p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoreValues;
