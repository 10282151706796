import { useState } from 'react';
import NSV1 from '../../Assets/Home/NSV1.png'
import NSV2 from '../../Assets/Home/NSV2.png'
import Mounted1 from '../../Assets/Home/Mounted1.png'
import Mounted2 from '../../Assets/Home/Mounted2.png'
import NABL1 from '../../Assets/Home/NABL1.png'
import NABL2 from '../../Assets/Home/NABL2.png'
import Bridge1 from '../../Assets/Home/Bridge1.png'
import Bridge2 from '../../Assets/Home/Bridge2.png'
import LabTestingbg from '../../Assets/Home/LabTesting.png'
import splashblue from '../../Assets/Home/splashblue.svg'
import navigationArrow from '../../Assets/Home/navigationArrow.svg'
import LabDetails from './LabDetails';

const NewLabTesting = () => {
    const [toggleState, setToggleState] = useState("Network Survey Vehicle")

    const data=[
        {
            name:"Network Survey Vehicle",
            heading1:"Network Survey Vehicle",
            points1:["Laser Profilometer","DGPS ROW Camera","PAVE Camera HRDMI (High Resolution Odometer)"," Road Geometry", "Laser Crack Measurement System (LCMS)"],
            heading2:"Data Collected by Survey Vehicle (NSV)",
            points2:["Road Roughness (Iri)"," Macrotexture And Longitudinal Profile"," Cracking (Width, Depth, Length, Type And Even Sealed Cracks)","Rut Depth, Width And Cross Sectional Area",", Shoving, Raveling Detection, Road Inventory"," Pothole Detection, Road Geometry"," Curb/Drop Off Detection, Location Referencing & GPS"," Bleeding Detection, Concrete Joints And Faulting"," Pavement Images Automatically Overlaid With Defects"," Gradient (%), Radius Of Curvature (M)","High Accuracy Distance/ Chainage"," Right Of Way (Row) Imagery(Assets)"," Pavement View Imagery (Condition)"],
            img1:NSV1,
            img2:NSV2
        },
        {
            name:"Vehcile Mounted Failling Weight Deflectometer",
            heading1:"Specification",
            points1:["Vehicle Mounted versions with 7 Sensors","Time history of load and deflection","Automatic surface IR-thermometer and air thermometer","Automatic distance meter"],
            heading2:"Load Pulse",
            points2:["Peak load range: 10 - 150 KN","Rise time: 12 ms-15","Number of drop heights: 4"],
            heading3:"Loading Plate",
            points3:["Type: 4 segments with individual tilt and individual vertical movement","Size: 300 mm diameter","Rubber under plate: 32 mm ribbed"],
            img1:Mounted1,
            img2:Mounted2
        },
        {
            name:"NABL Accredited Lab",
            heading1:"Saptagon Lab Services, a division of Saptagon(formerly known as G-Eng), is a premier providerof laboratory testing and analytical services for theconstruction and engineering sectors. Ouradvanced facilities and skilled professionalsensure the highest standards of quality andreliability in every test we perform. At Saptagon,we believe that the future of [Field/Discipline] isshaped by relentless curiosity, groundbreakingresearch, and unwavering dedication. Nestled atthe intersection of innovation and expertise, ourlab is a beacon for transformative research andcutting-edge technology.",
            img1:NABL1,
            img2:NABL2
        },
        {
            name:"Mobile Bridge Inspection Unit (MBIU)",
            points1:["16 M Boom, with Lowering Depth up to 21.7M","Bucket Type with Load Capacity of 280 KG","Safest on the Planet","2 Rotations with 0-90° and 0-180°", "Walk System (Vehicle Move with Remote Control)","Spare Power with DG Set","Completely Remote Control System","Cylindrical Stabilizer"],
            img1:Bridge1,
            img2:Bridge2
        },
    ]

    const renderComponent = () => {
        const selectedData = data.find(item => item.name === toggleState);
        if (!selectedData) return <div className='flex items-center justify-center text-center'><p>No Data Found! Try Again in Sometime.</p></div>;
    
        switch (toggleState) {
            case "Network Survey Vehicle":
                return <LabDetails data={selectedData} />;
            case "Vehcile Mounted Failling Weight Deflectometer":
                return <LabDetails data={selectedData} />;
            case "Mobile Bridge Inspection Unit (MBIU)":
                return <LabDetails data={selectedData} />;
            case "NABL Accredited Lab":
                return <LabDetails data={selectedData} />;
            default:
                return null;
        }
      };
    
    return (
        <div className='flex flex-col items-center px-0 md:px-14 py-10'>
            <div className='z-10 relative flex flex-col items-center justify-center gap-4 mb-10 bg-no-repeat bg-cover bg-center w-full min-h-[26dvh] md:min-h-[35dvh] rounded-xl' style={{backgroundImage:`url(${LabTestingbg})`}}>
                <div className='z-20 absolute w-full min-h-[26dvh] md:min-h-[35dvh] bg-[rgba(0,0,0,0.3)] backdrop-blur-[2px] rounded-xl'></div>
                <img src={splashblue} alt="" className='z-40 absolute bottom-6 md:bottom-2 w-[75%] md:w-[30%]'/>
                <p className='z-40 text-white text-3xl md:text-4xl font-semibold'>Lab & Machinery</p>
                <p className='z-40 text-white text-md md:text-lg tracking-wide'>Cutting-Edge In-House Equipment</p>
                <p className='z-40 text-white text-2xl md:text-3xl text-center font-semibold md:mt-4'>{toggleState}</p>
            </div>
            <div className='w-full flex flex-col items-center gap-6'>
                <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-center gap-2 md:gap-10 px-4 md:px-14">
                    {data.map((item,i)=>(
                        <div className="flex gap-2 md:gap-10 items-center flex-row-reverse md:flex-row">
                            <button
                                className={
                                    toggleState === item.name
                                    ? "purple font-semibold text-left md:text-center"
                                    : "darkgray font-semibold text-left md:text-center"
                                }
                                onClick={() => setToggleState(item.name)}
                                >
                                {item.name}
                            </button>
                            <img src={navigationArrow} alt="" className={
                                    toggleState === item.name
                                    ? "purple"
                                    : "darkgray"
                                }
                            />
                        </div>
                    ))}
                </div>
                
                <div className='w-full flex flex-col items-center justify-center pt-10 md:pt-20'>
                    {renderComponent()}
                </div>
            </div>
        </div>
    )
}

export default NewLabTesting