import Hero from "../Components/Career/Hero"

const Career = () => {
  return (
    <div className="circularstd">
        <Hero />
    </div>
  )
}

export default Career