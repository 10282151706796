import React, { useState } from 'react'
import CompletedProjects from './CompletedProjects';
import OngoingProjects from './OngoingProjects';
import projectbg from '../../Assets/Home/ProjectBG2.png'

const ProjectStatus = () => {
    const [toggleState, setToggleState] = useState(1);
  
    return (
        <div className='max-sm:px-6 max-xl:px-10 flex flex-col justify-center items-center gap-10 px-14 pb-20'>
            <div className='relative max-sm:h-[10vh] max-xl:h-[20vh] w-full h-[40vh] flex justify-center items-center projectbg rounded-2xl' style={{backgroundImage: `url(${projectbg})`, backgroundSize:"cover",backgroundPosition:"center", backgroundRepeat:"no-repeat"}}>
                <div className='absolute w-full z-10 h-full backdrop-blur-sm rounded-2xl'>ads</div>
                <p className='max-sm:text-2xl z-20 text-white text-3xl md:text-5xl font-semibold'>Projects</p>
            </div>
            <div className='w-full relative flex items-start justify-center'>
                <div className='w-full flex flex-col items-center'>
                    <div className="flex gap-8">
                        <button
                            className={
                            toggleState === 1
                                ? "home-tabs2 home-active-tabs2 rounded-full rounded-bl-full"
                                : "home-tabs2"
                            }
                            onClick={() => setToggleState(1)}
                        >
                            Completed Projects
                        </button>
                        <button
                            className={
                            toggleState === 2
                                ? "home-tabs2 home-active-tabs2 rounded-full rounded-br-full"
                                : "home-tabs2"
                            }
                            onClick={() => setToggleState(2)}
                        >
                            Ongoing Projects
                        </button>
                    </div>
                    <div className="w-full">
                        {toggleState === 1 ? <CompletedProjects /> : null}
                        {toggleState === 2 ? <OngoingProjects /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectStatus