import { useEffect, useState } from 'react'
import Careerbg from '../../Assets/Home/CareerBg.png'
import searchicon from '../../Assets/Home/searchicon.svg'
import categoryicon from '../../Assets/Home/Categoryicon.svg'
import arrowblog from "../../Assets/Home/arrowblog.svg";
import subscribeicon from "../../Assets/Home/subscribe.svg";
import CareerFooter from "../../Assets/Home/CareerFooter.png";
import { API, MEDIA_URL2 } from '../../axios'
import PositionSkeleton from './PositionSkeleton'
import PositionCards from './PositionCards';
import MobCareerFooter from "../../Assets/Home/MobCareerFooter.png";
import emailicon from "../../Assets/Home/email.svg";

const Hero = () => {
  const locationicon = MEDIA_URL2+'Career/location.svg'
  const experienceicon = MEDIA_URL2+'Career/experience.svg'
  const keywordsicon = MEDIA_URL2+'Career/keywords.svg'
  const dividericon = MEDIA_URL2+'Career/divider.svg'

  const [allVacancy, setAllVacancy] = useState([]);
  const [filteredVacancy, setFilteredVacancy] = useState([]);
  const [industry, setIndustry] = useState('');
  const [location, setLocation] = useState('');
  const [keyword, setKeyword] = useState('');
  const [isloading, setisloading] = useState(true)
  const [visibleJobs, setVisibleJobs] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState('');
  

  const handleLoadMore = () => {
    setVisibleJobs((prevVisiblePosts) => prevVisiblePosts + 3);
  };
  

  // Fetch vacancies on component mount
  useEffect(() => {
    const getVacancy = async () => {
        try {
            const res = await API.get(`/jd/jobpost/`);
            const activeVacancy = res.data.filter(job => job.status === 'active');
            setAllVacancy(activeVacancy);
            setFilteredVacancy(activeVacancy);
        } catch (err) {
            console.log('Error Occurred! Please try again after a while.');
        }
        setisloading(false)
    };
    getVacancy();
  }, []);

  const filterVacancies = () => {
      const filtered = allVacancy.filter(job => {
          return (
              (industry === '' || job.department_name.toLowerCase().includes(industry.toLowerCase())) &&
              (location === '' || job.location.toLowerCase().includes(location.toLowerCase())) &&
              (keyword === '' || job.position_name.toLowerCase().includes(keyword.toLowerCase())) &&
              (selectedCategory === '' || job.department_name.toLowerCase().includes(selectedCategory.toLowerCase()))
          );
      });
      setFilteredVacancy(filtered);
  };
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      filterVacancies();
    }
  };
  

  // Filter by Category Logic
  const category = allVacancy.map(vacancy => vacancy.department_name).filter((name, index, arr) => name && arr.indexOf(name) === index);
  
  const handleCategoryClick = (item) => {

    if (selectedCategory === item) {
      setSelectedCategory('');
      setFilteredVacancy(allVacancy);
    } else {
      setSelectedCategory(item);
      const filteredJobs = allVacancy.filter(job => job.department_name.toLowerCase() === item.toLowerCase());
      setFilteredVacancy(filteredJobs);
    }
    filterVacancies();
  };
  
  return (
    <div className='w-full flex flex-col items-center'>
      <div className="relative bg-cover md:bg-contain bg-no-repeat h-[60dvh] bg-right-bottom md:bg-center md:h-[70dvh] w-full flex items-center justify-center" style={{ backgroundImage: `url(${Careerbg})` }}>
        <div className="relative flex flex-col items-center gap-8 md:gap-14 text-center text-white px-4">
          <h1 className="text-2xl md:text-5xl font-medium">Find Jobs, Hire Creatives</h1>
          {/* Filter Section */}
          <div className="relativ w-[90dvw] md:w-[90%] flex flex-col md:flex-row gap-4 md:gap-10 md:gap-4 py-4 bg-white text-gray-700 md:py-3 md:pl-6 md:pr-4 px-4 cursor-pointer shadow-[0_0_40px_1px_rgba(0,0,0,0.2)] md:shadow-[0_0_40px_1px_rgba(0,0,0,0.1)] rounded-xl">
            <div className='flex gap-2'>
                <img src={experienceicon} alt="Industry" className='w-6' />
                <input
                    type="text"
                    placeholder="Industry"
                    value={industry}
                    onChange={(e) => setIndustry(e.target.value)}
                    onKeyDown={handleKeyPress}
                    className="md:text-md py-2 py-0 px-2 md:w-[80%] w-full"
                    />
            </div>
            
            <img src={dividericon} alt="Career" className='max-sm:hidden' />
            <div className='flex gap-2'>
                <img src={locationicon} alt="Location" className='w-6' />
                <input
                    type="text"
                    placeholder="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    onKeyDown={handleKeyPress}
                    className="md:text-md py-2 py-0 px-2 md:w-[80%] w-full"
                    />
            </div>
            <img src={dividericon} alt="Career" className='max-sm:hidden' />
            <div className='flex gap-2'>
                <img src={keywordsicon} alt="Keywords" className='w-6' />
                <input
                    type="text"
                    placeholder="Keyword"
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                    onKeyDown={handleKeyPress}
                    className="md:text-md py-2 py-0 px-2 md:w-[80%] w-full"
                    />
            </div>
            <button onClick={filterVacancies} className="flex items-center gap-3 md:px-8 p-4 md:py-4 bg-[#8364C3] text-white md:text-md text-lg hover:bg-[#6c44bc] rounded-lg duration-300"><img src={searchicon} alt="Search Jobs" className='md:w-7 w-6'/>Search</button>
          </div>
        </div>
      </div>
      
      {/* Job Openings Section */}
      <div className="flex flex-col items-start gap-8 px-4 py-10 md:p-14">
        <div className="flex flex-col items-start gap-2 md:gap-4">
          <p className="darkblue text-3xl md:text-4xl font-semibold">Jobs of the day</p>
          <p>Search and connect with the right job faster</p>
        </div>
        
        {/* Jobs Category */}
        <div className="w-full flex flex-wrap items-center gap-4">
          {category.map((item,i)=>(
            <div key={i} onClick={(e) => handleCategoryClick(item)} 
            className={`flex items-center gap-2 border-[1px] cursor-pointer rounded-lg p-2 md:p-4 hover:border-[#8364C3] hover:text-[#8364C3] duration-300`}><img src={categoryicon} alt={item} className='w-4 md:w-5'/>{item}</div>
          ))}
        </div>

        {/* Job Openings */}
        <div className="w-full grid md:grid-cols-3 grid-cols-1 gap-6">
            {isloading && <PositionSkeleton cards={6}/>}
            {filteredVacancy.slice(0, visibleJobs).map((item,i)=>{
                return(
                    <PositionCards data={item}/>
                )
            })}
        </div>
        {visibleJobs < filteredVacancy.length && (
                <div className="w-full flex justify-center items-center">
                    <button
                        onClick={handleLoadMore}
                        className="group px-6 py-2 flex gap-2 items-center border-[1px] border-gray-800 text-gray-800 font-medium text-base rounded-md mt-4 hover:border-[#8364C3] hover:text-[#8364C3]"
                    >
                        Load More{" "}
                        <img
                            src={arrowblog}
                            alt=""
                            className="h-3 group-hover:mt-2 duration-500"
                        />
                    </button>
                </div>
            )}
      </div>
      {/* Footer */}
      <div className='mobile-background w-[100%] md:w-[92%] min-h-[60vh] md:min-h-[70vh] bg-contain bg-center bg-no-repeat flex flex-col gap-8 items-center justify-center h-auto py-10 md:py-20' style={{backgroundImage: `url(${CareerFooter})`}}>
        <style>{`
                @media (max-width: 768px) {
                    .mobile-background {
                        background-image: url(${MobCareerFooter}) !important;
                        background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            `}
        </style>
        <div className='w-full min-h-[62dvh] flex flex-col gap-6 md:gap-8 items-center justify-end pb-6 md:pb-10'>
            <p className='w-[80%] md:w-[30%] leading-normal text-xl md:text-4xl md:font-semibold text-center text-white'>New Things Will Always Update Regularly</p>
            <div className='md:hidden flex flex-col items-center gap-4'>
                <div className='w-[90%] flex items-center justify-between gap-2 py-0 px-3 md:px-4 bg-white text-gray-700 cursor-pointer rounded-lg'>
                    <img src={emailicon} alt="Industry" className='md:w-6 w-8' />
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="md:text-lg py-3 md:py-4 px-2 md:w-[80%] w-full"
                        />
                </div>
                <button className="flex items-center gap-3 px-4 py-3 md:py-4 bg-white purple rounded-lg duration-300"><img src={subscribeicon} alt="Search Jobs" className='!invert md:w-6 w-5'/>Subscribe</button>
            </div>
            <div className="relative md:w-[45%] hidden md:flex items-center justify-between py-0 md:py-4 bg-white text-gray-700 py-3 px-4 cursor-pointer shadow-[0_0_40px_1px_rgba(0,0,0,0.2)] md:shadow-[0_0_40px_1px_rgba(0,0,0,0.1)] rounded-xl">
                <div className='flex gap-2'>
                    <img src={emailicon} alt="Industry" className='md:w-6 w-8' />
                    <input
                        type="email"
                        placeholder="Enter your email"
                        className="md:text-md text-lg py-4 px-2 md:w-[80%] w-full"
                        />
                </div>
                <button className="flex items-center gap-3 md:px-8 px-4 py-4 bg-[#8364C3] text-white md:text-md text-lg hover:bg-[#6c44bc] rounded-lg duration-300"><img src={subscribeicon} alt="Search Jobs" className='md:w-6 w-5'/>Subscribe</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Hero