import React from 'react'
import getintouch from '../../Assets/Home/getintouch.svg'
import Getintouchnew from '../../Assets/Home/Getintouchnew.png'
import { NavLink } from 'react-router-dom'

const Getintouch = () => {
  return (
    <div className='relative md:h-[40vh] md:mb-20 my-14 md:my-10 bg-no-repeat bg-cover' style={{backgroundImage:`url(${getintouch})`}}>
        <div className='flex md:flex-row flex-col items-center justify-center gap-10 md:gap-20'>
            <img src={Getintouchnew} alt="" className='w-[80%] md:w-[22%]'/>
            <div className='w-full md:w-[45%] flex flex-col items-center md:items-start justify-center text-center md:text-left h-full md:gap-8 gap-4 px-4 md:px-0'>
                <p className='text-2xl md:text-3xl darkblue font-semibold'>Get in Touch With Us</p>
                <p className='w-full font-normal text-sm md:text-md leading-snug'>If you are interested in learning more about what we do, be sure to contact us today! We would love to help you take your business to the next level.</p>
                <div className='flex gap-8 mt-4 md:mt-0'>
                    <NavLink to='/Contact-us'>
                        <div className="btn transition-all rounded-full text-sm md:text-md px-4 py-[12px] text-white">
                            Contact us Today
                        </div>    
                    </NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Getintouch