import '../Styles/Lab.css'
// import LabTesting from '../Components/Lab/LabTesting'
import NewLabTesting from '../Components/Lab/NewLabTesting'

const Lab = () => {
  return (
    <div className='circularstd'>
        {/* <LabTesting/> */}
        <NewLabTesting/>
    </div>
  )
}

export default Lab