import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import sliderrightarrow from '../../../Assets/Home/SliderRightArrow.svg'
import sliderleftarrow from '../../../Assets/Home/SliderLeftArrow.svg'
import AuthorityProjectsCards from './AuthorityProjectsCards'


function RightArrow(props){
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent"}}
      onClick={onClick}
    >
      <img src={sliderrightarrow} alt="Prev" style={{ width: "30px", height: "30px" }} />
      </div>
  );
}

function LeftArrow(props){
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent"}}
      onClick={onClick}
    >
      <img src={sliderleftarrow} alt="Prev" style={{ width: "30px", height: "30px" }} />
      </div>
  );
}

const AuthorityProjects = ({data}) => {
  
  var settings = {
    infinite: false,
    speed: 500,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 312,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='ourclients2 md:px-6 flex justify-start items-end'>
       <div className="slider-container max-[1024px]:mt-0" style={{width:"100%"}}>
        <Slider {...settings}>
          {data.map((items, index)=>(
              <AuthorityProjectsCards
                key={items.name+index}
                // Authority Projects
                img={items.img}
                name={items.NameOfProject}
                scope={items.ScopeofWork}
                client={items.Client}
                cost={items.Cost}
                // // Detail Design Projects
                // DetialProjectDescription={items.DetialProjectDescription}
                // // DPR Projects
                // ProjectCode={items.ProjectCode}
                // dprDescription={items.dprDescription}
                // dprLength={items.dprLength}
                // CAAmount={items.CAAmount}
                // dprClient={items.dprClient}
                // ConcernedDealing={items.ConcernedDealing}
                // LoaDate={items.LoaDate}
                // CompletionPeriod={items.CompletionPeriod}
                // PaymentStages={items.PaymentStages}
                // StagesCompleted={items.StagesCompleted}
                // BillRaised={items.BillRaised}
                // Billing={items.Billing}
                // EOT={items.EOT}
                // // Feasibility Report Projects
                // FRProjectName={items.FRProjectName}
                // FRDescription={items.FRDescription}
                // FRClient={items.FRClient}
                // FRCost={items.FRCost}
                // // Supervision Projects
                // SupervisionProjectName={items.SupervisionProjectName}
                // SupervisionDescription={items.SupervisionDescription}
                // SupervisionClient={items.SupervisionClient}
                // SupervisionCost={items.SupervisionCost}
                // // Operation Maintenance Projects
                // OMProjectName={items.OMProjectName}
                // OMDescription={items.OMDescription}
                // OMClient={items.OMClient}
                // OMCost={items.OMCost}
                // // Independent Engineer Projects
                // IEProjectName={items.IEProjectName}
                // IEDescription={items.IEDescription}
                // IEClient={items.IEClient}
                // IECost={items.IECost}
              />
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default AuthorityProjects