import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useSpring, animated, config } from 'react-spring';
import { MEDIA_URL } from '../../axios';
import projWorth from '../../Assets/Home/ProjWorth.svg';

const Stats = () => {
  const calendaricon = MEDIA_URL + 'Home/calendar.svg';
  const smileicon = MEDIA_URL + 'Home/smile.svg';
  const projectsicon = MEDIA_URL + 'Home/projects.svg';

  const statsRef = useRef(null);

  // Counter with animation effect
  function Counter({ n }) {
    const { number } = useSpring({
      from: { number: 0 },
      number: n,
      delay: 300,
      config: config.molasses,
    });
    return <animated.div>{number.to((n) => n.toFixed(0))}</animated.div>;
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const counterElement = (
            <>
              <div className='statsbg fade-in'></div>
              <div className='max-sm:mx-4 max-sm:flex-col max-sm:items-start max-sm:gap-4 flex justify-around items-center statscard mx-14 scale-in-center'>
                <div className='w-[75%] max-sm:w-full max-sm:px-0 flex max-sm:flex-col max-sm:gap-10 items-center justify-center'>
                  <div className='w-full flex items-center max-sm:items-start justify-between'>
                    <div className='max-sm:flex-col flex-1 flex items-center justify-center gap-4 slide-in-left'>
                      <div className='flex items-center'>
                        <img src={calendaricon} alt="Experience" className='max-sm:h-10 sm:max-xl:h-32 h-12' />
                      </div>
                      <div className='flex flex-col max-sm:items-center'>
                        <b className="max-sm:text-lg flex text-3xl text-white">
                          <Counter n={12} /><span>+</span>
                        </b>
                        <p className='text-sm md:text-md text-md text-white'>Successful Years</p>
                      </div>
                    </div>

                    <div className='max-sm:flex-col flex-1 flex items-center justify-center gap-4 slide-in-left'>
                      <div className='flex items-center'>
                        <img src={projectsicon} alt="Projects Completed" className='max-sm:h-10 h-14' />
                      </div>
                      <div className='flex flex-col max-sm:items-center'>
                        <b className="max-sm:text-lg flex text-3xl text-white">
                          <Counter n={150} /><span>+</span>
                        </b>
                        <p className='text-sm md:text-md text-white text-center md:text-left'>Successful Projects</p>
                      </div>
                    </div>
                  </div>

                  <div className='w-full flex items-center max-sm:items-start justify-center gap-4'>
                    <div className='max-sm:flex-col flex-1 flex items-center justify-center gap-4 slide-in-right'>
                      <div className='flex items-center'>
                        <img src={smileicon} alt="Clients" className='max-sm:h-10 h-12' />
                      </div>
                      <div className='flex flex-col max-sm:items-center'>
                        <b className="max-sm:text-lg flex text-3xl text-white">
                          <Counter n={30} /><span>+</span>
                        </b>
                        <p className='text-sm md:text-md text-md text-white'>Happy Clients</p>
                      </div>
                    </div>

                    <div className='max-sm:flex-col flex-1 flex items-center justify-center gap-4 slide-in-right'>
                      <div className='flex items-center'>
                        <img src={projWorth} alt="Projects Worth" className='max-sm:h-10 h-14' />
                      </div>
                      <div className='flex flex-col max-sm:items-center'>
                        <b className="max-sm:text-lg flex text-3xl text-white">
                          <Counter n={30} /><span>k Cr+</span>
                        </b>
                        <p className='text-sm md:text-md max-sm:text-center text-white'>Worth Projects Delivered</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
          ReactDOM.render(counterElement, document.getElementById('countscont'));
        }
      });
    });

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  return (
    <div
      id='countscont'
      className='max-sm:py-10 md:max-xl:pb-10 max-sm:mt-0 max-sm:mb-0 relative my-12 py-16 fade-in-section'
      ref={statsRef}
    ></div>
  );
};

export default Stats;
