import { Link } from 'react-router-dom'
import { MEDIA_URL2 } from '../../axios'
import expresswayicon from '../../Assets/Home/expressway.svg'
import waterwaysicon from '../../Assets/Home/waterways.svg'

const SectorMenu = ({ isVisible, onMouseEnter, onMouseLeave }) => {
    
    const roadsicon = MEDIA_URL2+'Sectormenu/roads.svg'
    const tunnelsicon = MEDIA_URL2+'Sectormenu/tunnels.svg'
    const transporticon = MEDIA_URL2+'Sectormenu/transport.svg'
    const urbanicon = MEDIA_URL2+'Sectormenu/urban.svg'
    const railwayicon = MEDIA_URL2+'Sectormenu/railway.svg'
    const metroicon = MEDIA_URL2+'Sectormenu/metro.svg'

    const sectormenudata=[
        {
            name:"Roads & Bridges",
            img:roadsicon,
            url:"Roads-&-Bridges",
        },
        {
            name:"Tunnels",
            img:tunnelsicon,
            url:"Tunnels",
        },
        {
            name:"Transport Planning ",
            img:transporticon,
            url:"Transport-Planning",
        },
        {
            name:"Urban Infrastructure",
            img:urbanicon,
            url:"Urban-Infrastructure",
        },
        {
            name:"Railway",
            img:railwayicon,
            url:"Railway",
        },
        {
            name:"Expressway",
            img:expresswayicon,
            url:"Expressway",
        },
        {
            name:"Metro",
            img:metroicon,
            url:"Metro",
        },
        {
            name:"Hydro Projects",
            img:waterwaysicon,
            url:"Waterways",
        },
        {
            name:"",
            img:"",
            url:"",
        },
    ]

  return (
    <div 
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave} 
    className={`z-40 fixed top-[40%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-wrap w-[60vw] h-[50vh] items-center gap-4 px-10 py-6 bg-white rounded-lg shadow-[0_0_30px_5px_rgba(0,0,0,0.1)] transition-all duration-1000 ${isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        {sectormenudata.map((i,index)=>(
            <div className={`sectormenu-cont flex items-center justify-start flex-[1_1_25%] rounded-md hover:bg-[rgba(245,245,245,0.7)] ${index === sectormenudata.length - 1 ? 'pointer-events-none' : ''}`}>
                <Link to={"Sectors/"+i.url} className='w-full px-4 py-6 flex gap-4 items-center justify-start'>
                    <img src={i.img} alt={i.name} />
                    <p className='black font-semibold'>{i.name}</p>
                </Link>
            </div>
        ))}
    </div>
  )
}

export default SectorMenu