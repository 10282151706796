import React from 'react'
import qs from 'qs';
import { useLocation } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { MEDIA_URL } from '../../axios';


const AllProjectDetails = () => {
  const Apco1 =MEDIA_URL +'Projects/Apco/Apco1.png';
  const Apco2 =MEDIA_URL +'Projects/Apco/Apco2.png';
  const projectbg  =MEDIA_URL +'Projects/projectbg.png';

  const data = [
    { src: Apco1, alt: 'Apco' },
    { src: Apco1, alt: 'Apco' },
    { src: Apco1, alt: 'Apco' },
    { src: Apco1, alt: 'Apco' },
  ];

  const data2 = [
    { src: Apco2, alt: 'Apco' },
    { src: Apco2, alt: 'Apco' },
    { src: Apco2, alt: 'Apco' },
    { src: Apco2, alt: 'Apco' },
  ];

  const location = useLocation();
  const { projectdetails } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const projectDetails = projectdetails ? JSON.parse(projectdetails) : null;


  return (
    <div className='max-xl:px-10 max-sm:gap-10 flex flex-col justify-center items-center gap-20 px-14 pb-20'>
      <div className='max-sm:h-[10vh] max-xl:h-[20vh] max-sm:gap-0 w-full h-[40vh] flex flex-col gap-6 justify-center items-center rounded-2xl' style={{backgroundImage: `url(${projectbg})`, backgroundSize:"cover", backgroundRepeat:"no-repeat"}}>
          <p className='max-sm:text-2xl max-sm:font-semibold text-white text-2xl'>Projects</p>
          <p className='max-sm:text-xl text-[#8364C3] max-sm:font-bold text-white text-6xl font-semibold'>{projectDetails[0].Association}</p>
      </div>
      <div className='w-full max-sm:flex-col flex gap-10'>
        <div className='max-sm:w-full w-[65%] flex flex-col gap-10'>
          <div className='max-sm:h-[24vh] h-[30vh] flex gap-12 py-4 px-6 bg-white border-t-8 border-[#8364C3] rounded-xl shadow-[0_0_10px_2px_rgba(0,0,0,0.1)]'>
            <div className='max-sm:text-base max-sm:font-semibold flex flex-col gap-2 items-start black text-lg'>
              <p>Association</p>
              <p>JV</p>
              <p>Lead</p>
              <p>Project Length (Km)</p>
              <p>Cost (Cr.)</p>
            </div>
            <div className='max-sm:text-base flex flex-col font-semibold items-start gap-2 text-lg blue'>
              <p>{projectDetails[0].Association}</p>
              <p>{projectDetails[0].JV}</p>
              <p>{projectDetails[0].Lead}</p>
              <p>{projectDetails[0].Projectlength}</p>
              <p>{projectDetails[0].Cost}</p>
              </div>
          </div>
          <div className='border-b-[2px] pb-24 max-sm:pb-4 flex flex-col gap-4'>
            <p className='purple font-semibold text-2xl'>Project Details</p>
            <p className='overflow-y-auto max-sm:w-full w-[90%] max-sm:h-auto h-[75vh] black text-justify text-lg py-0'>
              {projectDetails[0].projectdetails} <br /> <br />
            </p>
          </div>
        </div>

        <div className='max-sm:w-full w-[40%] flex flex-col gap-8'>
          <div className='max-sm:hidden projectpage w-full'>
            <Swiper
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
              spaceBetween={100}
              pagination={
                {
                  clickable:true
                }
              }
              modules={[FreeMode, Pagination]}
              className="mySwiper2"
              >
              {data.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image.src} alt={image.alt} className='max-sm:w-full' style={{ width: '100%', height: 'auto' }} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className={`projectpage w-full`}>
          <Swiper
              style={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
              }}
              spaceBetween={100}
              pagination={
                {
                  clickable:true
                }
              }
              modules={[FreeMode, Pagination]}
              className="mySwiper2"
              >
              {data2.map((image, index) => (
                <SwiperSlide key={index}>
                  <img src={image.src} alt={image.alt} style={{ width: '100%', height: 'auto' }} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllProjectDetails