import '../../Styles/Home.css';
import { NavLink } from 'react-router-dom';
import { MEDIA_URL } from '../../axios';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import heroimg1 from '../../Assets/Home/saptagon website images/denys-nevozhai-7nrsVjvALnA-unsplash.jpg';
import heroimg2 from '../../Assets/Home/saptagon website images/jakub-zerdzicki-C1ek0SJO8sY-unsplash.jpg';
import heroimg3 from '../../Assets/Home/saptagon website images/marcus-wallis-YrG8ufcfX0g-unsplash.jpg';
import { motion } from 'framer-motion';

const Hero = () => {
  const videoicon = MEDIA_URL+'Home/video-icon.svg';

  const officeimgs = [
    {
      img:heroimg1
    },{
      img:heroimg2
    },{
      img:heroimg3
    },
  ];

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };

  return (
    <div className='max-sm:flex-col-reverse max-sm:h-auto md:max-xl:h-[40vh] max-sm:items-center flex relative items-start max-sm:pt-4 sm:max-xl:pt-10 max-sm:justify-end max-sm:gap-16 justify-start h-[90vh]'>



<div className='max-sm:py-4 max-sm:gap-4 sm:max-xl:gap-4 max-sm:px-6 flex flex-col px-14 gap-8 z-10 fade-in'>
      <div className='max-xl:pt-0 max-sm:gap-4 sm:max-lg:pt-2 flex flex-col items-start gap-2 pt-20 pb-2 slide-in'>
        <span className='grace purple text-xl'>We make Difference</span>
        <p className='blue font-bold max-sm:w-[100%] text-2xl md:text-5xl w-[90%] leading-snug'>
          Building <span className='darkblue'>Tomorrow’s </span>
          <span className='darkblue'><br />Infrastructure,<span className='blue'>Today</span></span>
        </p>
      </div>
      <p className='max-sm:w-full sm:max-xl:text-justify sm:max-xl:w-[100%] w-[85%] leading-relaxed text-sm md:text-md fade-in' style={{textAlign: "justify"}}>
        Saptagon Asia Pvt Ltd (formerly G Eng Advisory Pvt Ltd) has led infrastructure innovation for over
        a decade with expertise in design, supervision, and NABL-accredited lab services.
        Our 1000+ professionals are dedicated to shaping the future of transportation with excellence and sustainability.
      </p>
      <div className='flex mt-2 fade-in'>
        <NavLink to='/Contact-us'>
          <div className="btn btn-hover transition-all text-sm md:text-md rounded-full px-6 py-[12px] text-white">
            Contact Us
          </div>    
        </NavLink>
      </div>
    </div>

    <div className='homeaboutus max-xl:hidden slider-container fade-in' style={{ width: "45%", margin: "55px 4% 0 0" }}>
  <Slider {...settings} className=''>
    {officeimgs.map((item, i) => (
      <img
        src={item.img}
        alt="Saptagon Office"
        className='h-[60vh] object-cover rounded-lg'
        style={{ borderRadius: "1rem" }}
        key={i}
      />
    ))}
  </Slider>
</div>
{/* Tablet Component */}
<div className='homeaboutus max-sm:hidden xl:hidden slider-container fade-in' style={{ width: "45%", margin: "0px 0 0 0" }}>
  <Slider {...settings}>
    {officeimgs.map((item, i) => (
      <img
        src={item.img}
        alt="Saptagon Office"
        className='h-[50vh] object-cover rounded-lg'
        style={{ borderRadius: "1rem" }}
        key={i}
      />
    ))}
  </Slider>
</div>
{/* Mobile Component*/}
<div className='homeaboutus sm:hidden slider-container fade-in' style={{ width: "100%", margin: "0 0 0 0" }}>
  <Slider {...settings}>
    {officeimgs.map((item, i) => (
      <img
        src={item.img}
        alt="Saptagon Office"
        className='object-cover rounded-lg'
        style={{ borderRadius: "1rem" }}
        key={i}
      />
    ))}
  </Slider>
</div>

    </div>
  )
}

export default Hero;
