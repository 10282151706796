import React from 'react';
import { motion } from 'framer-motion'; // Import framer-motion
import { MEDIA_URL } from '../../axios';
import mdoffice from '../../Assets/Home/MDOffice.png';

const OurObjective = () => {
  const aboutsplashbg = MEDIA_URL + 'Home/OurSolutionbg.png';

  // Animation variants for the image and text
  const imageVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', stiffness: 50, delay: 0.3 },
    },
  };

  const textVariant = {
    hidden: { opacity: 0, x: -50 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { type: 'spring', stiffness: 50, delay: 0.1 },
    },
  };

  return (
    <div className='relative overflow-hidden h-auto md:h-[80vh] flex items-center max-sm:items-start justify-start py-10 md:py-24 my-10'>
      {/* Sliding Image Animation */}
      <motion.img
        src={mdoffice}
        alt="Our Objective"
        className='max-md:hidden max-sm:w-full md:max-xl:top-[15vh] md:max-xl:right-4 md:max-xl:w-[45vw] w-[40vw] top-28 right-10 absolute z-0 rounded-xl'
        initial="hidden"
        animate="visible"
        variants={imageVariant}
      />
      <motion.img
        src={aboutsplashbg}
        alt="Our Objective"
        className='max-sm:top-[10vh] max-sm:w-[45vw] max-sm:left-[3vw] w-[20vw] top-[5vh] left-[14vw] absolute -z-10'
        initial="hidden"
        animate="visible"
        variants={imageVariant}
      />
      <motion.div
        className='flex flex-col herobg2 max-sm:gap-4 max-sm:px-6 px-14 gap-8 z-10'
        initial="hidden"
        animate="visible"
        variants={textVariant}
      >
        <p className='darkblue md:text-4xl text-2xl font-semibold'>Message from MD OFFICE</p>
        <div className='max-sm:w-full text-justify w-[95%] flex flex-col gap-4'>
          <p className='max-sm:w-full sm:max-xl:w-[55%] w-[50%] leading-relaxed text-sm md:text-md'>
            At Saptagon Asia Pvt Ltd (formerly G Eng Advisory Pvt Ltd), our journey
            over the past decade has positioned us as leaders in infrastructure
            consultancy. With our super team of over 500+ professionals,
            we've excelled in highway design, authority engineering, bridges, tunnels,
            and more.
          </p>
          <p className='max-sm:w-full sm:max-xl:w-[55%] w-[50%] leading-relaxed text-sm md:text-md'>
            Looking ahead, our focus for this decade is on advancing sustainable
            infrastructure solutions through cutting-edge technology. 
            We remain committed to maintaining leadership in excellence, innovation, 
            and sustainability, aiming to create impactful infrastructure that drives
            economic growth and enhances community well-being.
          </p>
          <p className='max-sm:w-full sm:max-xl:w-[55%] w-[50%] leading-relaxed text-sm md:text-md'>
            Thank you for your continued support as we navigate this exciting path forward. 
            <br/>
            <br/>

            Warm regards, <br/>
            Sushil Bhardwaj
          </p>
        </div>
        {/* Mobile Image Animation */}
        <div className='md:hidden flex justify-center items-start'>
          <motion.img
            src={mdoffice}
            alt="Quality Policy"
            className='w-full rounded-lg'
            initial="hidden"
            animate="visible"
            variants={imageVariant}
          />
        </div>
      </motion.div>
    </div>
  );
};

export default OurObjective;
