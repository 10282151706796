import labtestinglines from '../../Assets/Home/labtestinglines.svg'
import Labtesting2 from '../../Assets/Home/Labtesting2.png'
import MobLabtesting2 from '../../Assets/Home/MobLabtesting2.png'
import subscribeicon from "../../Assets/Home/subscribe.svg";
import CareerFooter from "../../Assets/Home/CareerFooter.png";
import MobCareerFooter from "../../Assets/Home/MobCareerFooter.png";
import emailicon from "../../Assets/Home/email.svg";

const LabDetails = ({data}) => {

    const data2 = [
        "Road Roughness (Iri)",
        "Curb/Drop Off Detection, Location Referencing & GPS",
        "Road Roughness (Iri)",
        "Macrotexture And Longitudinal Profile",
        "Right Of Way (Row) Imagery (Assets)",
        "Pavement Images Automatically Overlaid With Defects",
        "Pavement Images Automatically Overlaid With Defects",
        "Curb/Drop Off Detection, Location Referencing & GPS",
        "Gradient (%), Radius Of Curvature (M)",
        "Pothole Detection, Road Geometry",
        "Right Of Way (Row) Imagery (Assets)",
        "High Accuracy Distance/ Chainage"
    ];

    return (
        <div className='w-full flex flex-col items-center justify-start gap-14 border-t-2 pt-10'>
            <div className="hidden w-full relative md:flex flex-col items-center justify-start gap-10 bg-no-repeat bg-contain bg-center w-full h-[45dvh] px-4 md:px-14" style={{backgroundImage:`url(${labtestinglines})`}}>
                <div className='w-[25%] h-[26dvh] overflow-hidden absolute flex items-center justify-center rounded-2xl'>
                    <img src={data?.img1} alt={data?.name} className='rounded-2xl'/>
                </div>
                <div className='w-[94%] flex items-center justify-between'>
                    <p className='py-6 px-10 font-semibold text-black text-center bg-white rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300'>Laser Profilometer,<br /> DGPS</p>
                    <p className='py-6 px-10 font-semibold text-black text-center bg-white rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300'>Laser Crack Measurement <br />System (LCMS)</p>
                </div>
                <div className='w-[88%] flex items-center justify-between'>
                    <p className='py-6 px-10 font-semibold text-black text-center bg-white rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300'>ROW Camera, <br />PAVE Camera</p>
                    <p className='py-6 px-10 font-semibold text-black text-center bg-white rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300'>HRDMI <br />(High Resolution Odometer)</p>
                </div>
                <div className='w-full flex items-center justify-center'>
                    <p className='py-6 px-10 font-semibold text-black text-center bg-white rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300'>Road Geometry</p>
                </div>
            </div>
            <div className='md:hidden flex flex-col gap-4 px-4 md:px-14'>
                <img src={data?.img1} alt={data?.name} className='rounded-lg pb-6' />
                <p className='py-4 md:py-6 px-4 font-medium text-white text-sm md:text-md rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] bg-[#8364C3] cursor-pointer duration-300'>Laser Crack Measurement System (LCMS)</p>
                <p className='py-4 md:py-6 px-4 font-medium text-white text-sm md:text-md rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] bg-[#8364C3] cursor-pointer duration-300'>ROW Camera, PAVE Camera</p>
                <p className='py-4 md:py-6 px-4 font-medium text-white text-sm md:text-md rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] bg-[#8364C3] cursor-pointer duration-300'>Road Geometry</p>
                <p className='py-4 md:py-6 px-4 font-medium text-white text-sm md:text-md rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] bg-[#8364C3] cursor-pointer duration-300'>HRDMI (High Resolution Odometer)</p>
                <p className='py-4 md:py-6 px-4 font-medium text-white text-sm md:text-md rounded-lg shadow-[0_0_20px_-4px_rgba(0,0,0,0.15)] bg-[#8364C3] cursor-pointer duration-300'>Laser Profilometer, DGPS</p>
            </div>
            <div className='flex flex-col items-start gap-10 px-4 md:px-14'>
                <div className='relative flex flex-col pt-10'>
                    <p className='text-5xl md:text-8xl tracking-wider uppercase font-bold text-[#F4F4F4]'>Facilities</p>
                    <p className='text-2xl md:text-5xl purple font-semibold -mt-6 md:-mt-10'>Data Collected by {data?.name}</p>
                </div>
                <div className='flex flex-col items-center gap-0 md:gap-4'>
                    <img src={Labtesting2} alt="Lab & Machinery" className='hidden md:block w-[85%]'/>
                    <img src={MobLabtesting2} alt="Lab & Machinery" className='block md:hidden'/>
                    <div className='w-full grid gap-4 md:grid-cols-3 grid-cols-1'>
                        {data2.map((item, i)=>(
                           <p className={`bg-[#F3F0F9] px-6 py-6 md:py-8 rounded-lg purple font-semibold text-sm md:text-md md:font-medium hover:bg-[#8364C3] hover:text-white cursor-pointer duration-300 ${(i===1 || i===4 || i===7 || i===10)?"text-left md:text-center":(i===2 || i===5 || i===8 || i===11)?"text-left md:text-right":""}`}>{item}</p> 
                        ))}
                    </div>
                </div>
            </div>

            {/* Footer */}
            <div className='mobile-background md:w-[92%] min-h-[60vh] md:min-h-[70vh] bg-contain bg-center bg-no-repeat flex flex-col gap-8 items-center justify-center h-auto py-10 md:py-20' style={{backgroundImage: `url(${CareerFooter})`}}>
                <style>{`
                        @media (max-width: 768px) {
                            .mobile-background {
                                background-image: url(${MobCareerFooter}) !important;
                                background-size: contain;
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }
                    `}
                </style>
                <div className='w-full min-h-[57dvh] flex flex-col gap-6 md:gap-8 items-center justify-end pb-6 md:pb-10'>
                    <p className='w-[80%] md:w-[30%] leading-normal text-xl md:text-4xl md:font-semibold text-center text-white'>New Things Will Always Update Regularly</p>
                    <div className='md:hidden flex flex-col items-center gap-4'>
                        <div className='w-[90%] flex items-center justify-between gap-2 py-0 px-3 md:px-4 bg-white text-gray-700 cursor-pointer rounded-lg'>
                            <img src={emailicon} alt="Industry" className='md:w-6 w-8' />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="md:text-lg py-3 md:py-4 px-2 md:w-[80%] w-full"
                                />
                        </div>
                        <button className="flex items-center gap-3 px-4 py-3 md:py-4 bg-white purple rounded-lg duration-300"><img src={subscribeicon} alt="Search Jobs" className='!invert md:w-6 w-5'/>Subscribe</button>
                    </div>
                    <div className="relative md:w-[45%] hidden md:flex items-center justify-between py-0 md:py-4 bg-white text-gray-700 py-3 px-4 cursor-pointer shadow-[0_0_40px_1px_rgba(0,0,0,0.2)] md:shadow-[0_0_40px_1px_rgba(0,0,0,0.1)] rounded-xl">
                        <div className='flex gap-2'>
                            <img src={emailicon} alt="Industry" className='md:w-6 w-8' />
                            <input
                                type="email"
                                placeholder="Enter your email"
                                className="md:text-md text-lg py-4 px-2 md:w-[80%] w-full"
                                />
                        </div>
                        <button className="flex items-center gap-3 md:px-8 px-4 py-4 bg-[#8364C3] text-white md:text-md text-lg hover:bg-[#6c44bc] rounded-lg duration-300"><img src={subscribeicon} alt="Search Jobs" className='md:w-6 w-5'/>Subscribe</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LabDetails